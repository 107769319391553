<i18n>
ru:
  chooseFileOrDragItHere: Выберите файл или перетащите его сюда
  fileFormat: Прикрепите файл в формате {format}
  fileFormatOr: Прикрепите файл в формате {formats} или {format}
  fileType: Неправильный тип файла
  fileSize: 'Размер файла: '
  sizeLimit: 'Максимальный размер файла '
ua:
  chooseFileOrDragItHere: Оберіть файл або перетягніть його сюди
  fileFormat: Додайте файл у форматі {format}
  fileFormatOr: Додайте файл у форматі {formats} або {format}
  fileType: Неправильний тип файлу
  fileSize: 'Розмір файлу: '
  sizeLimit: 'Максимальний розмір файлу '
us:
  chooseFileOrDragItHere: Choose a file or drag it here
  fileFormat: Attach a file in {format} format
  fileFormatOr: Attach a file in {formats} or {format} format
  fileType: Wrong file type
  fileSize: 'Filesize: '
  sizeLimit: 'Filesize limit is '
</i18n>

<template>
  <div
    class="v-arora-file-input v-d-flex v-flex-column"
    @dragend.prevent="onDragEnd"
    @dragleave.prevent="onDragEnd"
    @dragover.prevent="onDragOver"
    @drop.prevent="onDrop"
  >
    <label class="v-arora-file-input--label v-w-100 v-pointer v-d-flex v-align-items-center">
      <input
        :accept="allowedTypes.join(',')"
        :multiple="false"
        type="file"
        v-show="false"
        @change="onFileChange"
      />
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <icon-general-check
          v-if="file"
          class="v-arora-file-input--icon v-arora-file-input--icon__success v-mr-xs"
        />
        <icon-general-exclamation
          v-else-if="uploadFailSize || uploadFailType"
          class="v-arora-file-input--icon v-arora-file-input--icon__error v-mr-xs"
        />
        <icon-general-paperclip
          v-else
          class="v-arora-file-input--icon v-mr-xs"
        />
      </transition>
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="fileOverInput"
          v-html="translate('AroraFileInput.chooseFileOrDragItHere')"
        />
        <div
          v-else-if="uploadFailType"
          class="v-error-color"
          v-html="translate('AroraFileInput.fileType')"
        />
        <div
          v-else-if="uploadFailSize"
          class="v-error-color"
        >
          <span v-html="translate('AroraFileInput.sizeLimit')" />
          <common-file-size :bytes="maxBytes" />
        </div>
        <div
          v-else-if="file"
          class="v-w-100 v-d-flex v-justify-content-between v-align-items-center"
        >
          <div class="v-d-flex v-flex-column">
            <span
              class="v-file-upload-name"
              v-html="sanitize(file.name)"
            />
            <div class="v-fz-label v-body-text-color-light">
              <span v-html="translate('AroraFileInput.fileSize')" />
              <common-file-size
                :bytes="file.size"
                :fraction="2"
              />
            </div>
          </div>
          <icon-general-cross @click.stop="fileRemove" />
        </div>
        <div v-else>
          <span v-html="label" />
          <i
            v-if="required"
            class="v-required"
          />
        </div>
      </transition>
    </label>
    <div
      class="v-arora-file-input--file-formats"
      v-html="uploadFormat"
    />
  </div>
</template>

<script setup lang="ts">
import { MIME, type VElement } from '@arora/common'

const {
  allowedTypes,
  file,
  maxBytes = 500,
  required = false
} = defineProps<
  VElement & {
    allowedTypes: MIME[]
    file: File | null | undefined
    maxBytes?: number
  }
>()

const emit = defineEmits(['update:file'])

const { sanitize, translate } = useI18nSanitized()

function stringMIME(values: MIME[]): string {
  const mime = Object.entries(MIME)
    .filter(([, value]) => values.includes(value))
    .map(([key]) => `.${key}`)

  return mime.join(', ')
}

const uploadFailSize = ref<boolean>(false)
const uploadFailType = ref<boolean>(false)
const fileOverInput = ref<boolean>(false)

const uploadFormat = computed<string>(() =>
  allowedTypes.length > 1
    ? translate('AroraFileInput.fileFormatOr', {
        format: stringMIME([...allowedTypes].splice(-1)),
        formats: stringMIME([...allowedTypes].splice(0, allowedTypes.length - 1))
      })
    : translate('AroraFileInput.fileFormat', { format: stringMIME(allowedTypes) })
)

function fileRemove(): void {
  emit('update:file', null)
  uploadFailSize.value = false
  uploadFailType.value = false
}

function onDrop(event: DragEvent): void {
  fileOverInput.value = false
  emit('update:file', null)

  const files = event.dataTransfer?.files ?? []

  if (files.length > 0) {
    validateFile(files[0])
  }
}

function onDragOver(): void {
  fileOverInput.value = true
}

function onDragEnd(): void {
  fileOverInput.value = false
}

function onFileChange(event: Event): void {
  emit('update:file', null)
  if (event.target && 'files' in event.target) {
    const target = event.target as HTMLInputElement
    if (target.files?.length) {
      validateFile(target.files[0])
    }
  }
}

function validateFile(file: File): void {
  if (file.size > maxBytes) {
    uploadFailSize.value = true

    return
  }

  uploadFailSize.value = false

  const type = allowedTypes.find((type) => type === file.type)

  if (type) {
    emit('update:file', file)

    uploadFailType.value = false
  } else {
    uploadFailType.value = true
  }
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

function preventDefaults(event: Event): void {
  event.preventDefault()
}

onMounted(() => {
  for (const eventName of events) {
    document.body.addEventListener(eventName, preventDefaults)
  }
})

onUnmounted(() => {
  for (const eventName of events) {
    document.body.removeEventListener(eventName, preventDefaults)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-arora-file-input {
  gap: 5px;

  &--title {
    font-size: 0.9rem;
  }

  &--icon {
    width: 24px;
    height: 24px;
    fill: none;
    border-radius: 50%;

    &__success {
      background: variables.$SuccessColor;
      color: variables.$SuccessForeColor;
      padding: 4px;
    }

    &__error {
      background: variables.$ErrorColor;
      color: variables.$ErrorForeColor;
    }
  }

  &--label {
    border: 1px dashed variables.$BodyTextColor;
    border-radius: variables.$BorderRadiusInput;

    padding: 0.65rem 1.15rem;
  }

  &--file-formats {
    color: variables.$BodyTextColorLight;
    font-size: 0.9rem;
  }
}
</style>
