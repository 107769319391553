import { createI18n } from 'vue-i18n'

function plural(choice: number, choicesLength: number): number {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

export default defineNuxtPlugin(({ vueApp }) => {
  const appConfig = useAppConfig()

  const lang = appConfig.VueSettingsPreRun.SelectedLanguage.toLowerCase()

  let currency: object = {
    maximumFractionDigits: appConfig.RestaurantSettingsPreRun.CurrencyDecimalPlaces,
    minimumFractionDigits: appConfig.RestaurantSettingsPreRun.CurrencyDecimalPlaces,
    style: 'decimal',
    trailingZeroDisplay: appConfig.RestaurantSettingsPreRun.CurrencyDecimalRoundZeroes
      ? 'stripIfInteger'
      : 'auto'
  }

  let nutritionalInfo: object = {
    maximumFractionDigits: appConfig.RestaurantSettingsPreRun.NutritionalDecimalPlaces,
    minimumFractionDigits: appConfig.RestaurantSettingsPreRun.NutritionalDecimalPlaces,
    style: 'decimal',
    trailingZeroDisplay: 'stripIfInteger'
  }

  try {
    if (
      Intl.NumberFormat('en', { signDisplay: 'negative' }).resolvedOptions().signDisplay !== undefined
    ) {
      currency = {
        maximumFractionDigits: appConfig.RestaurantSettingsPreRun.CurrencyDecimalPlaces,
        minimumFractionDigits: appConfig.RestaurantSettingsPreRun.CurrencyDecimalPlaces,
        signDisplay: 'negative',
        style: 'decimal',
        trailingZeroDisplay: appConfig.RestaurantSettingsPreRun.CurrencyDecimalRoundZeroes
          ? 'stripIfInteger'
          : 'auto'
      }

      nutritionalInfo = {
        maximumFractionDigits: appConfig.RestaurantSettingsPreRun.NutritionalDecimalPlaces,
        minimumFractionDigits: appConfig.RestaurantSettingsPreRun.NutritionalDecimalPlaces,
        signDisplay: 'negative',
        style: 'decimal',
        trailingZeroDisplay: 'stripIfInteger'
      }
    }
  } catch (error) {
    console.error(`[i18n] Exception in pre-build setup`, error)
  }

  const usDefaultTexts = {
    defaultTexts: {
      aboutUs: 'About Us',
      actions: 'Promotions',
      agreement: 'User agreement',
      articles: 'Articles',
      cart: 'Cart',
      contacts: 'Contact information',
      delivery: 'Delivery',
      feedback: 'Feedback',
      mainPage: 'Main page',
      offer: 'Public offer',
      orderComplete: 'Thank you for your order',
      partnership: 'Partnership',
      paymentRules: 'Payment terms',
      personalCabinet: 'Personal account',
      personalData: 'Personal data processing policy',
      personalDataAgreement: 'Personal data agreement',
      pointsPage: 'Bonus system terms and conditions',
      restaurants: 'Restaurants',
      vacancies: 'Job vacancies'
    }
  }

  const uaDefaultTexts = {
    defaultTexts: {
      aboutUs: 'Про нас',
      actions: 'Акції',
      agreement: 'Користувацька угода',
      articles: 'Стаття',
      cart: 'Кошик',
      contacts: 'Контактна інформація',
      delivery: 'Доставка',
      feedback: 'Відгуки',
      mainPage: 'Головна',
      offer: 'Публічна оферта',
      orderComplete: 'Дякуємо за замовлення',
      partnership: 'Партнерство',
      paymentRules: 'Умови оплати',
      personalCabinet: 'Особистий кабінет',
      personalData: 'Політика обробки персональних даних',
      personalDataAgreement: 'Згода на обробку персональних даних',
      pointsPage: 'Умови бонусної системи',
      restaurants: 'Ресторан',
      vacancies: 'Вакансії'
    }
  }

  const ruDefaultTexts = {
    defaultTexts: {
      aboutUs: 'О нас',
      actions: 'Акции',
      agreement: 'Пользовательское соглашение',
      articles: 'Статьи',
      cart: 'Корзина',
      contacts: 'Контакты',
      delivery: 'Доставка',
      feedback: 'Отзывы',
      mainPage: 'Главная',
      offer: 'Публичная оферта',
      orderComplete: 'Спасибо за заказ',
      partnership: 'Партнерство',
      paymentRules: 'Условия оплаты',
      personalCabinet: 'Личный кабинет',
      personalData: 'Политика обработки персональных данных',
      personalDataAgreement: 'Согласие на обработку персональных данных',
      pointsPage: 'Условия бонусной системы',
      restaurants: 'Рестораны',
      vacancies: 'Вакансии'
    }
  }

  /**
   * @ts-expect-error sometimes there are no texts which breaks vue app
   */
  const i18n = createI18n({
    datetimeFormats: {
      ru: {
        date: {
          day: 'numeric',
          month: 'long'
        },
        long: {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'short',
          weekday: 'short',
          year: 'numeric'
        },
        orderDate: {
          day: 'numeric',
          month: 'long'
        },
        orderTime: {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'long'
        },
        short: {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'numeric',
          year: 'numeric'
        },
        shortDate: {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        },
        time: {
          hour: 'numeric',
          minute: 'numeric'
        }
      },
      ua: {
        date: {
          day: 'numeric',
          month: 'long'
        },
        long: {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'short',
          weekday: 'short',
          year: 'numeric'
        },
        orderDate: {
          day: 'numeric',
          month: 'long'
        },
        orderTime: {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'long'
        },
        short: {
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          month: 'numeric',
          year: 'numeric'
        },
        shortDate: {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        },
        time: {
          hour: 'numeric',
          minute: 'numeric'
        }
      },
      us: {
        date: {
          day: 'numeric',
          hour12: true,
          month: 'long'
        },
        long: {
          day: 'numeric',
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
          month: 'short',
          weekday: 'short',
          year: 'numeric'
        },
        orderDate: {
          day: 'numeric',
          hour12: true,
          month: 'long'
        },
        orderTime: {
          day: 'numeric',
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
          month: 'long'
        },
        short: {
          day: 'numeric',
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
          month: 'numeric',
          year: 'numeric'
        },
        shortDate: {
          day: 'numeric',
          hour12: true,
          month: 'short',
          year: 'numeric'
        },
        time: {
          hour: 'numeric',
          hour12: true,
          minute: 'numeric'
        }
      }
    },
    defaultScope: 'global',
    fallbackLocale: 'us',
    fallbackWarn: false,
    globalInjection: false,
    legacy: false,
    locale: appConfig.VueSettingsPreRun.SelectedLanguage.toLowerCase(),
    messages: {
      ru: lang === 'ru' ? { ...ruDefaultTexts, ...(appConfig.Texts as any) } : {},
      ua: lang === 'ua' ? { ...uaDefaultTexts, ...(appConfig.Texts as any) } : {},
      us: lang === 'us' ? { ...usDefaultTexts, ...(appConfig.Texts as any) } : {}
    },
    missingWarn: false,
    numberFormats: {
      ru: {
        currency: currency,
        currencyCompact: { ...currency, notation: 'compact' },
        int: {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: 'decimal'
        },
        nutritional: nutritionalInfo,
        nutritionalFixed: {
          ...nutritionalInfo,
          maximumFractionDigits: 2,
          minimumFractionDigits: 1
        }
      },
      ua: {
        currency: currency,
        currencyCompact: { ...currency, notation: 'compact' },
        int: {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: 'decimal'
        },
        nutritional: nutritionalInfo,
        nutritionalFixed: {
          ...nutritionalInfo,
          maximumFractionDigits: 2,
          minimumFractionDigits: 1
        }
      },
      us: {
        currency: currency,
        currencyCompact: { ...currency, notation: 'compact' },
        int: {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: 'decimal'
        },
        nutritional: nutritionalInfo,
        nutritionalFixed: {
          ...nutritionalInfo,
          maximumFractionDigits: 2,
          minimumFractionDigits: 1
        }
      }
    },
    pluralRules: {
      ru: plural,
      ua: plural
    },
    warnHtmlMessage: false
  })

  vueApp.use(i18n)
})
