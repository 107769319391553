<template>
  <section class="v-groups-main__Isengard v-position-relative">
    <div
      v-if="appConfig.VueSettingsPreRun.MenuMainGroupsInRow === 2"
      class="v-position-absolute v-groups-main__Isengard--addition"
    />
    <common-cards-flex-mesh
      class="v-group-isengard-wrapper"
      class-name="v-group-isengard-card"
      :items="menuStore.GroupsMainPage"
      :max-items="appConfig.VueSettingsPreRun.MenuMainGroupsInRow"
      :min-items="appConfig.VueSettingsPreRun.MenuMainGroupsInRow === 4 && !isExtraSmall ? 2 : 1"
    >
      <template #item="item: Group">
        <arora-nuxt-link
          :class-name="`v-group-isengard v-group-isengard__items${appConfig.VueSettingsPreRun.MenuMainGroupsInRow}`"
          :href="item.Link"
          :label="sanitize(item.Name)"
        >
          <div
            v-if="isSmall && imageExists(item.ImageForMobile)"
            class="v-group-isengard--image"
          >
            <arora-nuxt-image
              :alt="sanitize(item.Name)"
              :image="item.ImageForMobile"
              image-type="Group"
            />
          </div>
          <div
            v-else-if="imageExists(item.Image)"
            class="v-group-isengard--image"
          >
            <arora-nuxt-image
              :alt="item.Name"
              :image="item.Image"
              image-type="Group"
            />
          </div>
          <div class="v-group-isengard--text">
            <span
              class="v-group-isengard--title"
              v-html="sanitize(item.Name)"
            />
            <div
              v-if="appConfig.VueSettingsPreRun.MenuMainGroupsSubtitle === 'min-price'"
              class="v-group-isengard--subtitle"
              :class="
                appConfig.VueSettingsPreRun.MenuMainGroupsInRow === 4
                  ? 'v-d-flex v-justify-content-center'
                  : ''
              "
            >
              <span
                class="v-mr-xxs"
                v-html="translate('groupsPage.fromPrice')"
              />
              <common-currency :amount="item.ProductsMinPrice" />
            </div>
            <div
              v-else-if="appConfig.VueSettingsPreRun.MenuMainGroupsSubtitle === 'amount'"
              class="v-group-isengard--subtitle"
              :class="
                appConfig.VueSettingsPreRun.MenuMainGroupsInRow === 4
                  ? 'v-d-flex v-justify-content-center v-align-items-center'
                  : ''
              "
            >
              <span
                v-html="
                  translate('groupsPage.count', { n: item.ProductsCountTotal }, item.ProductsCountTotal)
                "
              />
              <icon-general-chevron-right
                v-if="appConfig.VueSettingsPreRun.MenuMainGroupsInRow === 4"
                class="v-group-isengard--chevron-small v-ml-xxs"
              />
            </div>
          </div>
          <div
            v-if="appConfig.VueSettingsPreRun.MenuMainGroupsInRow === 2"
            class="v-group-isengard--chevron"
          >
            <icon-general-chevron-right />
          </div>
        </arora-nuxt-link>
      </template>
    </common-cards-flex-mesh>
  </section>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

const menuStore = useMenuStore()

const { sanitize, translate } = useI18nSanitized()
const { isExtraSmall, isSmall } = useWindowSize()
const { imageExists } = useImageInfo()
const appConfig = useAppConfig()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-groups-main__Isengard {
  font-family: variables.$FontFamilyMenuGroups;

  &--addition {
    background: variables.$SecondaryBackgroundColor;
    height: 100%;
    width: 160px;
    left: -80px;
    border-radius: 45px;
  }

  .v-group-isengard-wrapper {
    padding: 45px 0;
  }
  .v-group-isengard-card {
    @include mixins.trans;

    display: flex;
    justify-content: center;

    .v-group-isengard {
      display: grid;
      gap: 2rem;
      grid-template-areas: 'image text chevron';

      &__items4 {
        grid-template-columns: 1fr;
        grid-template-rows: 120px 1fr;
        grid-template-areas:
          'image'
          'text';
        padding: 0 16px 16px;

        .v-group-isengard--image {
          display: flex;
          justify-content: center;
          .v-img-fluid {
            height: 120px;
            width: auto;
          }
        }

        .v-group-isengard--title {
          text-align: center;
          padding-bottom: 0.25rem;
        }
        .v-group-isengard--subtitle {
          text-align: center;
        }
      }

      &__items2 {
        width: 100%;
        padding: 0.5rem;
        grid-template-columns: 100px minmax(0, 1fr) 40px;
        grid-template-rows: 1fr;

        .v-group-isengard--image {
          height: 100px;
        }
      }

      &--image {
        grid-area: image;

        .v-img-fluid {
          border-radius: calc(variables.$BorderRadius - 0.5rem);
          overflow: hidden;
        }
      }

      &--text {
        grid-area: text;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      &--title {
        font-size: 2rem;
        font-weight: 600;
        color: variables.$BodyTextColor;

        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--subtitle {
        font-size: variables.$TextSizeH3;
        color: variables.$PrimaryBackgroundColor;
        display: flex;
      }

      &--chevron-small {
        width: auto;
        height: 1.1rem;
        fill: none;
      }

      &--chevron {
        grid-area: chevron;
        color: variables.$SecondaryBackgroundColor;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 30px;
          height: auto;
          fill: none;
        }
      }

      @include mixins.sm {
        gap: 1rem;
      }
    }

    &:hover {
      scale: 0.98;
    }
  }
}
</style>
