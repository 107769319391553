<i18n>
ru:
  apartment: Квартира
  building: Корпус
  doorway: Подъезд
  floor: Этаж
  intercom: Домофон
  office: Офис/кабинет
  orderDeliveryTypes: Тип доставки
  orderTypeError: Выберите место получения заказа
  orderTypeFlat: Квартирный дом
  orderTypeHouse: Частный дом
  orderTypeOrg: Организация
  title: Дополнительная информация
ua:
  apartment: Квартира
  building: Будова
  doorway: Під`їзд
  floor: Поверх
  intercom: Домофон
  office: Офіс/кабінет
  orderDeliveryTypes: Тип доставки
  orderTypeError: Виберіть місце отримання замовлення
  orderTypeFlat: Квартирний будинок
  orderTypeHouse: Приватний будинок
  orderTypeOrg: Організація
  title: Додаткова інформація
us:
  apartment: Apartment
  building: Building
  doorway: Doorway
  floor: Floor
  intercom: Intercom
  office: Office/cabinet
  orderDeliveryTypes: Delivery type
  orderTypeError: Please select order type
  orderTypeFlat: Apartment
  orderTypeHouse: House
  orderTypeOrg: Organization
  title: Additional info
</i18n>

<template>
  <template v-if="loaded">
    <div
      v-if="orderDeliveryTypes.length > 0"
      class="v-additional-form-address-typed v-additional-form-address-typed-custom"
      :class="{ 'v-flex-column': appConfig.VueSettingsPreRun.AdditionalInfoShowAsColumn }"
    >
      <div
        class="v-additional-order-delivery-type v-mb-xs"
        v-form-validator="{
          Form: form,
          Value: orderDeliveryType <= 0 ? null : orderDeliveryType,
          Required: true,
          Validations: []
        }"
        data-test-id="additional-order-delivery-type"
      >
        <arora-radio-input
          hide-label
          required
          :label="translate('additionalInfo.orderDeliveryTypes')"
          :options="orderDeliveryTypes"
          v-model:selected="orderDeliveryType"
        >
          <template #option="option">
            <span
              v-if="option.value === OrderDeliveryType.Flat"
              data-test-id="order-user-delivery-type-flat"
              v-html="translate('additionalInfo.orderTypeFlat')"
            />
            <span
              v-else-if="option.value === OrderDeliveryType.House"
              data-test-id="order-user-delivery-type-house"
              v-html="translate('additionalInfo.orderTypeHouse')"
            />
            <span
              v-else-if="option.value === OrderDeliveryType.Org"
              data-test-id="order-user-delivery-type-org"
              v-html="translate('additionalInfo.orderTypeOrg')"
            />
          </template>
        </arora-radio-input>
      </div>
      <transition
        appear
        mode="out-in"
        name="slidedown"
      >
        <div
          v-if="orderDeliveryType === OrderDeliveryType.Flat"
          class="v-additional-order-delivery-type-flat"
          data-test-id="additional-order-delivery-type-flat"
        >
          <div
            v-if="showTitle"
            class="v-address-form-subtitle"
            v-html="translate('additionalInfo.title')"
          />
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              required
              v-form-validator="{
                Form: form,
                Value: apartment,
                Required: true,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.apartment')"
              data-test-id="order-user-apartment"
              v-model:text="apartment"
            />
          </div>
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: doorway,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.doorway')"
              data-test-id="order-user-doorway"
              v-model:text="doorway"
            />
          </div>
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: floor,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.floor')"
              data-test-id="order-user-floor"
              v-model:text="floor"
            />
          </div>
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: intercom,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.intercom')"
              data-test-id="order-user-intercom"
              v-model:text="intercom"
            />
          </div>
        </div>
        <div
          v-else-if="orderDeliveryType === OrderDeliveryType.Org"
          class="v-additional-order-delivery-type-org"
          data-test-id="additional-order-delivery-type-org"
        >
          <div
            v-if="showTitle"
            class="v-address-form-subtitle"
            v-html="translate('additionalInfo.title')"
          />
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: apartment,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.office')"
              data-test-id="order-user-office"
              v-model:text="apartment"
            />
          </div>
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: doorway,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.doorway')"
              data-test-id="order-user-doorway"
              v-model:text="doorway"
            />
          </div>
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: floor,
                Required: false,
                Validations: ['digits', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.floor')"
              data-test-id="order-user-floor"
              v-model:text="floor"
            />
          </div>
          <div class="v-address-form-wrapper">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: form,
                Value: intercom,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.intercom')"
              data-test-id="order-user-intercom"
              v-model:text="intercom"
            />
          </div>
        </div>
      </transition>
    </div>
    <div
      v-else
      class="v-additional-form-address v-additional-form-address-custom"
      :class="{ 'v-flex-column': appConfig.VueSettingsPreRun.AdditionalInfoShowAsColumn }"
      data-test-id="additional-form-address"
    >
      <div
        v-if="!appConfig.VueSettingsPreRun.DoorwayHidden"
        class="v-address-form-quarter"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: form,
            Value: intercom,
            Required: false,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.doorway')"
          :required="appConfig.VueSettingsPreRun.DoorwayRequired"
          data-test-id="order-user-doorway"
          v-model:text="doorway"
        />
      </div>

      <div
        v-if="!appConfig.VueSettingsPreRun.BuildingHidden"
        class="v-address-form-quarter"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: form,
            Value: building,
            Required: appConfig.VueSettingsPreRun.BuildingRequired,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.building')"
          :required="appConfig.VueSettingsPreRun.BuildingRequired"
          data-test-id="order-user-building"
          v-model:text="building"
        />
      </div>

      <div
        v-if="!appConfig.VueSettingsPreRun.ApartmentHidden"
        class="v-address-form-quarter"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: form,
            Value: apartment,
            Required: appConfig.VueSettingsPreRun.ApartmentRequired,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.apartment')"
          :required="appConfig.VueSettingsPreRun.ApartmentRequired"
          data-test-id="order-user-apartment"
          v-model:text="apartment"
        />
      </div>

      <div
        v-if="!appConfig.VueSettingsPreRun.IntercomHidden"
        class="v-address-form-quarter"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: form,
            Value: intercom,
            Required: appConfig.VueSettingsPreRun.IntercomRequired,
            Validations: ['noSpace', 'length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.intercom')"
          :required="appConfig.VueSettingsPreRun.IntercomRequired"
          data-test-id="order-user-intercom"
          v-model:text="intercom"
        />
      </div>

      <div
        v-if="!appConfig.VueSettingsPreRun.FloorHidden"
        class="v-address-form-quarter"
      >
        <v-input
          class-name="v-arora-input"
          v-form-validator="{
            Form: form,
            Value: floor,
            Required: appConfig.VueSettingsPreRun.FloorRequired,
            Validations: ['length'],
            MaxLength: 10
          }"
          :label="translate('additionalInfo.floor')"
          :required="appConfig.VueSettingsPreRun.FloorRequired"
          data-test-id="order-user-floor"
          v-model:text="floor"
        />
      </div>
    </div>
  </template>
  <div v-else>
    <common-skeleton :loaders-in-row="4" />
  </div>
  <transition
    v-if="appConfig.VueSettingsPreRun.OrderDeliveryTypeEnable"
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="orderDeliveryType === 0"
      class="v-error-color v-mb-sm"
      v-html="translate('additionalInfo.orderTypeError')"
    />
  </transition>
  <cart-comment />
</template>

<script setup lang="ts">
import type { HasValidatorForm } from '~types/props'

import { useCommon, vFormValidator, VInput } from '@arora/common'

import { OrderDeliveryType } from '~api/consts'

withDefaults(
  defineProps<
    HasValidatorForm & {
      showTitle?: boolean
    }
  >(),
  {
    form: 'cart',
    showTitle: true
  }
)

const clientStore = useClientStore()

const { clean, translate } = useI18nSanitized()
const { debounce } = useCommon()
const appConfig = useAppConfig()

const loaded = computed(() => clientStore.ClientState?.data !== null)

onMounted(async () => {
  await clientStore.initClientState()
})

const orderDeliveryTypes = computed<number[]>(() => {
  const result: number[] = []

  if (!appConfig.VueSettingsPreRun.OrderDeliveryTypeEnable) return result

  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowFlat) result.push(OrderDeliveryType.Flat)
  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowHouse) result.push(OrderDeliveryType.House)
  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowOrg) result.push(OrderDeliveryType.Org)

  return result
})

const orderDeliveryType = computed<number>({
  get: () => {
    return clientStore.ClientState.data?.SelectedDeliveryAddress?.OrderDeliveryType ?? 0
  },
  set: (value) => {
    clientStore.updateDeliveryAddressSingle({
      orderDeliveryType: value
    })
  }
})

const doorwayLocal = ref<string | null>(null)

const doorway = computed<string>({
  get: () => {
    return doorwayLocal.value ?? clientStore.ClientState.data?.SelectedDeliveryAddress?.Doorway ?? ''
  },
  set: (value) => {
    doorwayLocal.value = clean(value)
    debounce(() =>
      clientStore.updateDeliveryAddressSingle({
        doorway: doorwayLocal.value ?? ''
      })
    )
  }
})

const buildingLocal = ref<string | null>(null)

const building = computed<string>({
  get: () => {
    return buildingLocal.value ?? clientStore.ClientState.data?.SelectedDeliveryAddress?.Building ?? ''
  },
  set: (value) => {
    buildingLocal.value = clean(value)
    debounce(() =>
      clientStore.updateDeliveryAddressSingle({
        building: buildingLocal.value ?? ''
      })
    )
  }
})

const apartmentLocal = ref<string | null>(null)

const apartment = computed<string>({
  get: () => {
    return apartmentLocal.value ?? clientStore.ClientState.data?.SelectedDeliveryAddress?.Apartment ?? ''
  },
  set: (value) => {
    apartmentLocal.value = clean(value)
    debounce(() =>
      clientStore.updateDeliveryAddressSingle({
        apartment: apartmentLocal.value ?? ''
      })
    )
  }
})

const intercomLocal = ref<string | null>(null)

const intercom = computed<string>({
  get: () => {
    return intercomLocal.value ?? clientStore.ClientState.data?.SelectedDeliveryAddress?.Intercom ?? ''
  },
  set: (value) => {
    intercomLocal.value = clean(value)
    debounce(() =>
      clientStore.updateDeliveryAddressSingle({
        intercom: intercomLocal.value ?? ''
      })
    )
  }
})

const floorLocal = ref<string | null>(null)

const floor = computed<string>({
  get: () => {
    return floorLocal.value ?? clientStore.ClientState.data?.SelectedDeliveryAddress?.Floor ?? ''
  },
  set: (value) => {
    floorLocal.value = clean(value)
    debounce(() =>
      clientStore.updateDeliveryAddressSingle({
        floor: floorLocal.value ?? ''
      })
    )
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-additional-form-address {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1.1rem;
  margin-left: -1.1rem;
}

.v-additional-form-address-typed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-bottom: 1.1rem;

  border: 1.5px solid variables.$BorderColor;
  border-radius: variables.$BorderRadius;
}

.v-address-form-subtitle {
  flex: 0 0 100%;
  max-width: 100%;
  color: variables.$BodyTextColorLight;

  margin-bottom: 10px;
}

.v-additional-order-delivery-type {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 1.1rem;

  &-flat {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.1rem;
    column-gap: 1.1rem;

    .v-address-form-wrapper {
      flex: 0 0 calc(50% - 0.55rem);
      max-width: calc(50% - 0.55rem);
    }
  }

  &-org {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.1rem;
    column-gap: 1.1rem;

    .v-address-form-wrapper {
      flex: 0 0 calc(50% - 0.55rem);
      max-width: calc(50% - 0.55rem);
    }
  }
}

.v-address-form-quarter {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 1.1rem;
}

@include mixins.sm {
  .v-address-form-quarter {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
