<i18n>
ru:
  changedMaxCountMessage: 'Следующие товары недоступны в указанном количестве:'
  changesPriceProductsMessage: 'Цена на следующие товары была изменена:'
  ok: ОК
  title: Внимание!
  unavailableProductsMessage: 'Следующие товары недоступны для заказа и будут удалены
    из корзины:'
ua:
  changedMaxCountMessage: 'Наступні товари недоступні у вказаній кількості:'
  changesPriceProductsMessage: 'Ціна на наступні товари була змінена:'
  ok: ОК
  title: Увага!
  unavailableProductsMessage: 'Наступні товари недоступні для замовлення та будуть
    видалені з кошика:'
us:
  changedMaxCountMessage: 'The following items are not available in the specified quantity:'
  changesPriceProductsMessage: 'The price of the following items has been changed:'
  ok: OK
  title: Attention!
  unavailableProductsMessage: 'The following items are not available for order and
    will be removed from the cart:'
</i18n>

<template>
  <div class="v-popup-head v-title">
    <div
      class="v-popup-products-title"
      v-html="translate('changedProductsPopup.title')"
    />
  </div>
  <div class="v-popup-body">
    <div class="v-products-block-container">
      <div
        v-for="(section, index) in content"
        :key="`products-block#${index}`"
        class="v-products-block"
      >
        <span
          class="v-products-block__message v-mb-sm"
          v-html="section.message"
        />
        <div
          v-for="item in section.products"
          :key="`changed-product#${sanitize(item.ID)}`"
          class="v-products-block__product-item v-mb-sm v-pb-sm"
        >
          <div class="v-products-block__product-item--image v-mr-sm">
            <arora-nuxt-image
              :alt="item.Product.Name"
              :image="item.Product.SmallImageURL"
              image-type="ProductSmall"
            />
          </div>
          <div class="v-products-block__product-item--content">
            <div
              class="v-products-block__product-item--name"
              v-html="sanitize(item.Name)"
            />
            <div class="v-products-block__product-item--description">
              <template v-if="item.SelectedOption">
                <div
                  v-html="sanitize(`${item.SelectedOption.Name} ${item.SelectedOption.Description}`)"
                />
              </template>
              <template
                v-if="!item.Product.IsCombine && !stringIsNullOrWhitespace(item.ProductComposition)"
              >
                <span v-html="translate('itemsPage.productDescription')" />
                <span v-html="sanitize(item.ProductComposition)" />
              </template>
              <template v-if="item.Product.IsCombine && item.Slots.length > 0">
                <span v-html="translate('itemsPage.additionalSlotsTitle')" />
                <span v-html="item.Slots.map((s) => sanitize(s.Name)).join(', ')" />
              </template>
              <template v-if="item.Modifiers.length > 0 && item.Modifiers.length > 0">
                <span v-html="translate('itemsPage.additionalModifiersTitle')" />
                <span
                  v-html="
                    item.Modifiers.map((modifier) =>
                      sanitize(`${modifier.Name} x ${modifier.Count}`)
                    ).join(', ')
                  "
                />
              </template>
            </div>
            <div class="v-products-block__product-item--price v-mb-xs">
              <cart-product-price :product="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="v-text-right">
      <arora-button
        class-name="v-btn v-btn-link v-btn-text v-no-underline"
        ignore-settings
        :label="translate('changedProductsPopup.ok')"
        data-test-id="changed-products-popup-button"
        @click="handleOkClick"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { popupChangedProductsProps } from '~types/popupStore'

import { useCommon } from '@arora/common'
const { okFunction } = defineProps<popupChangedProductsProps>()

const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()

const popupStore = usePopupStore()

const handleOkClick = (): void => {
  okFunction()
  popupStore.closePopup()
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-popup-head {
  .v-popup-products-title {
    width: 100%;
    font-weight: bold;
    text-align: center;
    font-size: variables.$TextSizeH3;
  }
}
.v-popup-content {
  .v-popup-body {
    .v-products-block-container {
      display: flex;
      flex-direction: column;

      .v-products-block {
        display: flex;
        flex-direction: column;

        &__message {
          font-size: 1rem;
        }

        &__product-item {
          position: relative;
          display: flex;
          border-bottom: 1px solid variables.$BorderColor;

          &:last-of-type {
            border-bottom: none;
          }

          &--image {
            flex: 0 0 25%;
          }

          &--content {
            display: flex;
            flex-direction: column;
            flex: 0 0 75%;
          }

          &--name {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 5px;
          }

          &--description {
            flex-grow: 1;
            font-size: 0.9rem;
            color: variables.$BodyTextColorLight;
          }

          &--price {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
