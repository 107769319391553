<template>
  <floating-vue-menu
    compute-transform-origin
    eager-mount
    handle-resize
    :auto-hide="showOnClick || !isDesktopVersion"
    :delay="delay"
    :disabled="disabled"
    :distance="6"
    :placement="placement"
    :popper-class="fromPopupLocal ? 'v-popup-popper' : ''"
    :popper-triggers="showOnClick || !isDesktopVersion ? [] : ['hover', 'focus']"
    :show-group="`popover-group-${uid}`"
    :theme="showOnClick ? `${theme}-with-overlay` : theme"
    :triggers="showOnClick || !isDesktopVersion ? ['click'] : ['hover', 'focus']"
    v-model:shown="isMenuShown"
    @hide="onHide"
    @show="onShow"
  >
    <slot name="hoverable">
      <span class="v-popover-icon v-pointer">
        <icon-menu-info />
      </span>
    </slot>

    <template #popper>
      <slot name="content" />
      <div
        v-if="showOnClick && !fromPopupLocal && isDesktopVersion"
        class="v-popover-close-button"
        @click="() => (isMenuShown = false)"
      >
        <icon-general-cross />
      </div>
    </template>
  </floating-vue-menu>
</template>

<script setup lang="ts">
import type { PopoverPlacement } from '~types/props'

import { type CanBeDisabled, useWindowSize } from '@arora/common'

import { Menu as FloatingVueMenu } from 'floating-vue'
import 'floating-vue/dist/style.css'

const {
  delay = 0,
  ignoreTimer = true,
  placement = 'bottom',
  showOnClick = false,
  theme = 'product'
} = defineProps<
  CanBeDisabled & {
    theme?: string
    asMenu?: boolean
    placement?: PopoverPlacement
    showOnClick?: boolean
    ignoreTimer?: boolean
    delay?: number
  }
>()

const { isDesktopVersion } = useWindowSize()
const { fromPopup } = useInstance()
const uid = useId()
const router = useRouter()

const fromPopupLocal = fromPopup.value

const isMenuShown = ref<boolean>(false)
let timer: number | unknown = 0

function onShow(): void {
  if (showOnClick && !fromPopupLocal) {
    document.body.classList.add('v-open-overflow')
  }

  if (!isDesktopVersion.value && !showOnClick && !ignoreTimer) {
    hidePopover()
  }
}

function onHide(): void {
  if (showOnClick && !fromPopup.value) {
    document.body.classList.remove('v-open-overflow')
  }

  clearInterval(Number(timer))
}

function hidePopover(): void {
  timer = setTimeout(() => {
    isMenuShown.value = false
  }, 2500) as unknown
}

onMounted(() => {
  if (import.meta.client) {
    window.addEventListener('scroll', () => (isMenuShown.value = false))
  }
})

router.beforeEach(() => {
  isMenuShown.value = false
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-popper--theme-small-cart,
.v-popper--theme-small-cart-with-overlay {
  .v-popper__inner {
    background: variables.$ProductCardBackground;
    color: variables.$ProductCardColor;
    border-radius: variables.$BorderRadius;
    box-shadow: variables.$PopoverShadow;
    min-width: 450px;
    max-width: 450px;
    overflow: visible;
    padding: 12px;
  }

  .v-popper__arrow-inner,
  .v-popper__arrow-outer {
    visibility: visible;
    border-color: variables.$ProductCardBackground;
  }
}

.v-popper--theme-product,
.v-popper--theme-product-with-overlay {
  .v-popper__inner {
    background: variables.$ProductCardBackground;
    color: variables.$ProductCardColor;
    border-radius: variables.$BorderRadius;
    box-shadow: variables.$PopoverShadow;
    min-width: 150px;
    max-width: 360px;
    overflow: visible;
    padding: 12px;
  }

  .v-popper__arrow-inner,
  .v-popper__arrow-outer {
    visibility: visible;
    border-color: variables.$ProductCardBackground;
  }
}

.v-popper--theme-product-with-overlay {
  .v-popper__wrapper {
    .v-popper__inner {
      overflow: visible;
      margin: 0 30px 0 10px;

      @include mixins.lg {
        margin: 0 10px;
      }
    }
  }
}

.v-popper__wrapper {
  transition: transform 0.15s;
  .v-popper__inner {
    overflow: hidden;
  }
}

.v-popper__popper.v-popper__popper--hidden .v-popper__wrapper {
  transform: scale(0.5);
}

.v-popper--some-open--product-with-overlay {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: variables.$PopupOverlayBackground;
    z-index: 1011;
  }
}

.v-popover-close-button {
  position: absolute;
  top: -15px;
  right: -40px;
  font-size: 30px;
  opacity: 0.65;
  transition: opacity 0.25s ease-in;
  color: variables.$BodyBackground;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
</style>
