<i18n>
ru:
  option: '{num} опция'
ua:
  option: '{num} опція'
us:
  option: '{num} option'
</i18n>

<template>
  <div
    v-if="
      onlySliderVersion ||
      (options as T[]).length === 1 ||
      (isDesktopVersion &&
        (options as T[]).length > 0 &&
        (options as T[]).length < (threshold as number))
    "
    class="v-arora-option-slider"
    :class="[
      !stringIsNullOrWhitespace(theme as string)
        ? `v-arora-option-slider-${theme}`
        : 'v-arora-option-slider-default',
      disabled ? 'disabled' : ''
    ]"
    :data-test-id="dataTestId && `${dataTestId}-slider`"
    :style="`--width: ${width}px; --element:${elementWidth}px; --items: ${(options as T[]).length}; --height: ${height}px; --deviation: ${deviation}px; --minWidth:${minWidth}`"
    ref="toggleSwitcher"
  >
    <div
      v-for="(item, index) in options"
      :id="`${uid}-${index.toString()}`"
      :key="`${uid}-${index.toString()}`"
      class="v-arora-option-slider__item v-text-center"
      :class="{
        'v-active': index === pickedIndex,
        disabled: !indexEnabled(item),
        'v-pointer-events-none': (options as T[]).length === 1
      }"
      :data-test-id="
        dataTestId && `${dataTestId}-slider-option${index === pickedIndex ? '--selected' : ''}`
      "
      :aria-label="translate('AroraOptionSlider.option', { num: parseInt(index.toString()) + 1 })"
      @click="async () => (picked = item as T)"
    >
      <slot
        :value="item"
        name="option"
      />
      <icon-general-stop
        v-if="!indexEnabled(item)"
        class="v-arora-option-slider--stop-icon"
      />
    </div>
    <div
      :id="`${uid}-selected`"
      class="v-arora-option-slider__selected"
    />
  </div>
  <div
    v-else-if="(options as T[]).length > 0"
    class="v-w-100 v-mb-sm"
  >
    <common-dropdown-menu
      close-on-click
      :data-test-id="dataTestId"
      :disabled="disabled"
      :items="options as T[]"
      :label="label"
    >
      <template #title>
        <slot
          :value="picked"
          name="option"
        />
      </template>

      <template #item="item">
        <div
          class="v-ml-sm v-mb-xs v-pointer"
          :class="{
            'v-selected-in-dropdown': getDropdownSelectedOption(options as T[], item as T),
            disabled: !indexEnabled(item)
          }"
          :data-test-id="
            dataTestId &&
            `${dataTestId}-dropdown-single${getDropdownSelectedOption(options as T[], item as T) ? '--selected' : ''}`
          "
          @click="async () => (picked = item as T)"
        >
          <slot
            :value="item"
            name="option"
          />
          <span
            v-if="!indexEnabled(item) && !stringIsNullOrWhitespace(disabledText)"
            class="v-ml-xxs"
            v-html="disabledText"
          />
        </div>
      </template>

      <template #index="item: { index: number }">
        <div
          class="v-ml-sm v-mb-xs v-pointer"
          :class="{
            'v-selected-in-dropdown': pickedIndex === item.index,
            disabled: !indexEnabled((options as T[])[item.index])
          }"
          :data-test-id="
            dataTestId &&
            `${dataTestId}-dropdown-single${pickedIndex === item.index ? '--selected' : ''}`
          "
          @click="async () => (picked = (options as T[])[item.index])"
        >
          <slot
            :value="(options as T[])[item.index]"
            name="option"
          />
          <span
            v-if="!indexEnabled((options as T[])[item.index]) && !stringIsNullOrWhitespace(disabledText)"
            class="v-ml-xxs"
            v-html="disabledText"
          />
        </div>
      </template>
    </common-dropdown-menu>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { OptionTheme } from '~types/props'

import { useCommon, useWindowSize, type VElement } from '@arora/common'

import { gsap } from 'gsap'

const props = withDefaults(
  defineProps<
    VElement & {
      selected: T
      options: T[]
      threshold?: number
      height?: number
      theme?: OptionTheme | null
      indexEnabled?: (t: T) => boolean
      deviation?: number
      disabledText?: string
      onlySliderVersion?: boolean
    }
  >(),
  {
    deviation: 3,
    disabled: false,
    disabledText: '',
    height: 38,
    indexEnabled: (_t: T) => {
      return true
    },
    theme: null,
    threshold: 3
  }
)
const emit = defineEmits(['update:selected'])

const { translate } = useI18nSanitized()
const { getStyleWidth, stringIsNullOrWhitespace } = useCommon()
const { isDesktopVersion, windowSize } = useWindowSize()

const { deviation, indexEnabled, options, selected, threshold } = toRefs(props)
const picked = computed<T>({
  get() {
    if (selected.value === undefined) {
      emit('update:selected', options.value[0])

      return options.value[0]
    }

    return selected.value
  },
  set(value) {
    emit('update:selected', value)

    updateIndex(value)
  }
})

const pickedIndex = ref<number>(0)

const uid = useId()

let isAnimPlaying = false
function updateIndex(value: T): void {
  let selectedIndex = 0
  for (let index = 0; index < options.value.length; index++) {
    if (value && typeof value === 'object' && 'ID' in value) {
      if (options.value[index].ID === value.ID) {
        selectedIndex = index
      }
    } else if (options.value[index] === value) {
      selectedIndex = index
    }
  }

  const duration = pickedIndex.value === selectedIndex ? 0 : 0.4

  pickedIndex.value = selectedIndex

  if (options.value.length > 0 && options.value.length < threshold.value) {
    const element = document.getElementById(`${uid}-selected`)
    if (element && !isAnimPlaying) {
      isAnimPlaying = true
      const tl = gsap.timeline()

      tl.add('optionRoll', '+=0')
      tl.to(
        element,
        {
          duration: duration,
          ease: 'power2.in',
          onComplete: () => {
            isAnimPlaying = false
          },
          x: elementWidth.value * pickedIndex.value
        },
        'optionRoll'
      )

      if (duration > 0) {
        tl.to(
          element,
          {
            duration: duration * 0.7,
            ease: 'power2.in',
            scaleX: 1.5
          },
          'optionRoll'
        )

        tl.to(
          element,
          {
            duration: duration * 0.3,
            ease: 'power2.in',
            scaleX: 1
          },
          '>'
        )
      }
    }
  }
}

const width = ref<number>(0)
const minWidth = ref<string>('100%')
const elementWidth = ref<number>(0)

const toggleSwitcher = ref<HTMLDivElement>()

function updateSwitches(): void {
  if (toggleSwitcher.value) {
    const localWidth = getStyleWidth(toggleSwitcher.value)

    if (localWidth === 0) {
      setTimeout(() => {
        updateSwitches()
      }, 300)
      return
    }

    elementWidth.value = Math.floor((localWidth - deviation.value * 2) / options.value.length)
    width.value = elementWidth.value * options.value.length + deviation.value * 2
    minWidth.value = `${width.value}px`
  }
}

function getDropdownSelectedOption<T>(options: T[], item: T): boolean {
  if (typeof item === 'object' && item && 'key' in item) return item.key === pickedIndex.value

  return options.indexOf(item) === pickedIndex.value
}

onMounted(() => {
  updateSwitches()
  updateIndex(selected.value)
})

onUpdated(() => {
  updateSwitches()
  updateIndex(selected.value)
})

watch(
  () => windowSize.value,
  async (newState, oldState) => {
    if (newState !== oldState) {
      updateSwitches()
    }
  }
)

watch(
  () => selected.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) updateIndex(newValue)
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

$deviation: var(--deviation, 3px);
$width: var(--width, 100%);
$element: var(--element, 100%);
$height: var(--height, 34px);
$items: var(--items, 1);
$minWidth: var(--minWidth, 100%);

.v-arora-option-slider-product-card {
  border: 1px solid variables.$ProductCardOptionBorder;
  margin: 0 0 4px;

  .v-arora-option-slider__item {
    color: variables.$ProductCardColor;
    opacity: 0.5;

    &.v-active {
      opacity: 1;
      color: variables.$ProductCardOptionColor;
    }
  }

  .v-arora-option-slider__selected {
    background-color: variables.$ProductCardOptionBackground;
  }
}

.v-arora-option-slider-secondary {
  margin: 0;

  .v-arora-option-slider__item {
    &.v-active {
      opacity: 1;
      color: variables.$SecondaryColor;
    }
  }

  .v-arora-option-slider__selected {
    background-color: variables.$SecondaryBackgroundColor;
  }
}

.v-arora-option-slider-default {
  border: 1px solid variables.$BorderColor;
  margin: 0 0 4px;
}

.v-arora-option-slider-header {
  border: none;
  background: variables.$HeaderBackgroundColorContrast5;
  margin: 0;

  .v-arora-option-slider__item {
    color: variables.$HeaderColor;
    font-size: 1rem;
    height: $height;

    &:hover {
      background: variables.$HeaderBackgroundColorContrast10;
    }

    svg {
      fill: variables.$HeaderColor;
    }

    &.v-active {
      font-weight: 600;
      color: variables.$SecondaryColor;

      &:hover {
        background: none;
      }

      svg {
        fill: variables.$SecondaryColor;
      }
    }
  }

  .v-arora-option-slider__selected {
    height: $height;
    background-color: variables.$SecondaryBackgroundColor;
    top: 0;
  }
}

.v-arora-option-slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: $width;
  border-radius: variables.$BorderRadiusInput;
  padding: $deviation;
  position: relative;
  min-width: $minWidth;

  &--stop-icon {
    width: 1rem;
    height: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: transparent;
    z-index: 2;
    border-radius: variables.$BorderRadiusInput;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border: none;
    font-size: 0.9rem;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;

    min-width: $element;
    max-width: $element;
    height: $height;

    svg {
      transition-delay: 0.2s;
    }

    &:focus,
    &:active,
    &:visited {
      outline: none;
      box-shadow: none;
      border-radius: variables.$BorderRadiusInput;
    }

    &:hover {
      outline: none;
    }
  }

  &__selected {
    height: $height;
    width: calc($element - ($deviation / 2));
    position: absolute;
    z-index: 1;
    top: $deviation;
    border-radius: calc(variables.$BorderRadiusInput - $deviation);

    background-color: variables.$OptionsBackground;
  }
}

.v-selected-in-dropdown {
  color: variables.$PrimaryBackgroundColor;
  @include mixins.trans;

  &:hover {
    color: variables.$PrimaryBackgroundColorDarken;
  }
}
</style>
