<template>
  <div class="yandex-maps-terminals v-mb-sm">
    <div
      :id="uid"
      class="v-terminal-select-map-container"
    />
  </div>
  <template v-if="loaded && terminals.length > 0">
    <Teleport
      v-for="terminal in terminals"
      :key="`#self-service-${uid}-${sanitize(terminal.ID)}`"
      :to="`#self-service-${uid}-${sanitize(terminal.ID)}`"
    >
      <maps-common-self-service-icon :terminal="terminal" />
    </Teleport>
    <Teleport :to="`#${uid}-info-block`">
      <transition
        appear
        mode="out-in"
        name="slidedown"
      >
        <div
          v-if="selectedTerminal"
          class="v-d-flex v-align-items-center v-map-selected-terminal-info-block v-glassmorph"
        >
          <icon-general-information-circle class="v-mr-xs" />
          <span
            v-html="
              appConfig.VueSettingsPreRun.ShowCityInTerminalSelect
                ? `${translate('addressDelivery.cityShort', { city: selectedTerminal.City })}, ${sanitize(selectedTerminal.Address)}`
                : sanitize(selectedTerminal.Address)
            "
          />
        </div>
      </transition>
    </Teleport>
  </template>
</template>

<script setup lang="ts">
import type { YMapMarker } from '@yandex/ymaps3-types'
import type { Terminal } from '~types/addressStore'
import type { YMapBounds } from '~types/mapsStore'

import { Guid } from '~api/consts'

const loaded = ref<boolean>(false)

const addressStore = useAddressStore()
const clientStore = useClientStore()
const mapsStore = useMapsStore()

const { sanitize, translate } = useI18nSanitized()
const appConfig = useAppConfig()

const terminals = computed<Terminal[]>(() =>
  (addressStore.Terminals?.data ?? [])
    .filter((t) => t.Latitude !== 0 && t.Longitude !== 0)
    .filter(
      (t) => appConfig.VueSettingsPreRun.ShowDisabledTerminals || (t.Active && t.CanOrderSelfService)
    )
)

const selectedTerminal = computed<Terminal | null | undefined>(() => {
  if (!clientStore.ClientState.data) return null

  const terminalId = clientStore.ClientState.data?.SelectedSelfserviceTerminalID

  if (Guid.IsNullOrEmpty(terminalId)) return null

  return (addressStore.Terminals?.data ?? []).find((terminal) => terminal.ID === terminalId)
})

onMounted(async () => {
  await addressStore.initTerminals()
  if (mapsStore.YandexMaps.state === 'success') {
    await makeMap()
  } else {
    await mapsStore.initYandexMaps()
  }
})

const ymaps3Loaded = computed<boolean>(
  () => mapsStore.YandexMaps.state === 'success' && addressStore.Terminals?.data !== null
)

watch(ymaps3Loaded, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    await makeMap()
  }
})

let lock = false
let bounds: YMapBounds = {
  maxLatitude: 0,
  maxLongitude: 0,
  minLatitude: 9999,
  minLongitude: 9999
}

async function makeMap(): Promise<void> {
  if (lock) return
  lock = true
  if (terminals.value && terminals) {
    const placemarks: YMapMarker[] = []

    for (const terminal of terminals.value) {
      if (terminal.Latitude && terminal.Longitude) {
        const markerElement = document.createElement('div')
        markerElement.id = `self-service-${uid}-${sanitize(terminal.ID)}`
        const enabledTerminal = terminal.Active && terminal.CanOrderSelfService

        const marker = new ymaps3.YMapMarker(
          {
            coordinates: [terminal.Longitude, terminal.Latitude],
            id: terminal.ID,
            onClick: () => {
              for (const placemark of placemarks) {
                placemark.element.classList.remove('active')
              }

              clientStore.onChangeSelfServiceTerminal(terminal)
            }
          },
          markerElement
        )

        if (appConfig.VueSettingsPreRun.ShowDisabledTerminals || enabledTerminal) {
          placemarks.push(marker)

          if (terminal.Latitude > bounds.maxLatitude) bounds.maxLatitude = terminal.Latitude
          if (terminal.Longitude > bounds.maxLongitude) bounds.maxLongitude = terminal.Longitude

          if (terminal.Latitude < bounds.minLatitude) bounds.minLatitude = terminal.Latitude
          if (terminal.Longitude < bounds.minLongitude) bounds.minLongitude = terminal.Longitude
        }
      }
    }
    await mapsStore.makeYMap(uid, 'self-service', bounds)
    mapsStore.addYMapInfoBlock(uid, `${uid}-info-block`)

    mapsStore.addYMapsFeatures(uid, placemarks)
  }

  loaded.value = true
  lock = false
}

const uid = `map-terminals-${useId()}`
</script>

<style lang="scss">
@use '~/assets/variables';

.v-terminal-select-map-container {
  height: 400px;
  box-shadow: variables.$InputShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;
}

.v-map-selected-terminal-info-block {
  border-radius: variables.$BorderRadius;
  padding: 0.5rem;
  font-size: variables.$TextSizeLabel;
}
</style>
