<i18n>
ru:
  close: Ок
  title: 'Мы используем cookies, сервисы Google Analytics и Яндекс.Метрика'
  message: 'Продолжая пользоваться сайтом, Вы принимаете '
  messageLink: условия обработки персональных данных
ua:
  close: Ок
  title: 'Ми використовуємо cookies, сервіси Google Analytics і Яндекс.Метрика'
  message: 'Продовжуючи користуватися сайтом, Ви приймаєте '
  messageLink: умови обробки персональних даних
us:
  close: Ok
  title: 'We use cookies, Google Analytics services'
  message: 'By continuing to browse the site, you accept '
  messageLink: our personal data policy
</i18n>

<template>
  <transition
    appear
    name="fade"
  >
    <div
      v-if="!closedCookieNotification"
      class="v-container"
    >
      <div
        :id="uid"
        class="v-cookie-notification v-row"
      >
        <div
          class="v-col-12 v-col-sm-8 v-col-md-6 v-col-lg-6 v-col-xl-6 v-col-xxl-6 v-cookie-notification--content v-glassmorph"
          v-once
          data-test-id="cookie-notification-text"
        >
          <div class="v-d-flex v-flex-row-no-wrap v-justify-content-between v-align-items-center">
            <div>
              <span
                class="v-cookie-notification--title v-d-flex v-align-items-end"
                v-html="translate('cookieNotificator.title')"
              />
              <div class="v-cookie-notification--subtitle">
                <span
                  class="v-mr-xxs"
                  v-html="translate('cookieNotificator.message')"
                />

                <arora-nuxt-link
                  open-in-new-tab
                  :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
                  :label="translate('cookieNotificator.messageLink')"
                />
              </div>
            </div>
            <arora-button
              class-name="v-btn v-btn-secondary v-cookie-notification--button"
              ignore-settings
              :label="translate('cookieNotificator.close')"
              data-test-id="cookie-notification-close"
              @click="close"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'

const closedCookieNotification = useCookie('closedCookieNotification', {
  expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
})

const uid = useId()
const { translate } = useI18nSanitized()
const appConfig = useAppConfig()

function close(): void {
  if (document.getElementById(uid))
    gsap.to(`#${uid}`, {
      bottom: -10,
      duration: 0.85,
      ease: 'circ.out',
      height: 0,
      onComplete: () => {
        closedCookieNotification.value = 'true'
      },
      opacity: 0.85
    })
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-cookie-notification {
  position: fixed;
  bottom: 10px;
  z-index: 9998;
  padding: 5px;
  width: 100%;
  max-width: calc(variables.$ContainerWidth - 30px);
  overflow: hidden; //for animation

  @include mixins.md {
    bottom: 90px;
  }

  &--content {
    border-radius: variables.$BorderRadius;
    box-shadow: variables.$BannerShadow;
    padding: 0.85rem 1.145rem;
  }

  &--title {
    font-size: 0.715rem;
  }

  &--subtitle {
    font-size: 0.715rem;
    color: variables.$BodyTextColorLight;

    .v-link {
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      font-size: 0.715rem;
      color: variables.$BodyTextColorLight;
    }
  }

  &--button {
    padding: 0.575rem 0.85rem;
    border-radius: calc(variables.$BorderRadius / 2);
    min-width: 3.5rem;
  }
}
</style>
