<i18n>
ru:
  recommended: Рекомендуемое
ua:
  recommended: Рекомендовано
us:
  recommended: Recommended
</i18n>

<template>
  <div class="v-container v-py-lg">
    <div
      v-if="recommended && recommended.length > 0"
      class="v-recommended-old"
    >
      <template v-if="appConfig.VueSettingsPreRun.MenuRelatedShowAsList">
        <div
          class="v-recommended-title"
          v-html="translate('recommended.recommended')"
        />
        <common-cards-flex-mesh
          :class-name="`v-menu-related-${appConfig.VueSettingsPreRun.MenuRelatedItemsMax}`"
          :items="recommended"
          :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
          :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
        >
          <template #item="recommendation: ProductInList">
            <menu-product-in-list-item-card-by-type :product="recommendation" />
          </template>
        </common-cards-flex-mesh>
      </template>
      <arora-carousel
        v-else
        :max-items="appConfig.VueSettingsPreRun.MenuRelatedItemsMax"
        :show-part-of-next-slide="false"
        :title="translate('recommended.recommended')"
        navigation="top"
        snap-align="start"
      >
        <Slide
          v-for="recommendation in recommended"
          :key="recommendation.ID"
        >
          <menu-product-in-list-item-card-by-type
            disable-lazy
            :product="recommendation"
          />
        </Slide>
      </arora-carousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'

import { Guid } from '~api/consts'

const appConfig = useAppConfig()
const menuStore = useMenuStore()
const { translate } = useI18nSanitized()
const { makeDefaultOption } = useProduct()
const recommended = ref<ProductInList[] | null>(null)

onMounted(async () => {
  if (import.meta.client) {
    if (Guid.IsNullOrEmpty(menuStore.CurrentGroupId)) {
      recommended.value = await menuStore.getRecommended()
    } else {
      recommended.value = await menuStore.getRecommendedByGroupId()
    }

    for (const menuItem of recommended.value ?? []) {
      makeDefaultOption(menuItem)
    }
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-recommended-old {
  width: 100%;
  margin-bottom: 10px;
  @include mixins.swiper-button-products;
}

.v-recommended-title {
  font-weight: 600;
  font-size: variables.$TextSizeH2;
  margin-bottom: 10px;
  //todo: после того как переделаем шапку и сладйеры возможно надо будет поиграться с отступами.
  padding: 20px 0;
  @include mixins.sm {
    max-width: calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
  }
}
</style>
