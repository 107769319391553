<template>
  <div
    v-if="loadersInRow >= 1"
    class="v-skeleton-wrapper v-mb-sm"
    :style="`--width: ${width > 0 ? `${width}px` : `${100 / loadersInRow}%`}; --flex: 0 0 ${100 / loadersInRow}%; --height: ${height}px; --spaceBetween: ${spaceBetween}px;`"
  >
    <div
      v-for="index in loaders ?? loadersInRow"
      :key="`loader-${index}`"
      :class="
        loadersInRow === 1
          ? 'v-skeleton-wrapper-item'
          : 'v-skeleton-wrapper-item v-skeleton-wrapper-item__more_than_one'
      "
    >
      <span
        class="v-skeleton-loader"
        :class="[darkTheme ? 'v-skeleton-loader-dark' : 'v-skeleton-loader-light']"
      />
    </div>
  </div>
  <div
    v-else
    class="v-skeleton-wrapper-item"
  >
    <span
      class="v-skeleton-loader"
      :class="[darkTheme ? 'v-skeleton-loader-dark' : 'v-skeleton-loader-light']"
    />
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    loaders?: number | null
    loadersInRow?: number
    height?: number
    width?: number
    spaceBetween?: number
  }>(),
  {
    height: 35,
    loaders: null,
    loadersInRow: 1,
    spaceBetween: 16,
    width: 0
  }
)

const appConfig = useAppConfig()
const menuStore = useMenuStore()

const darkTheme = computed<boolean>(() =>
  menuStore.CurrentGroup?.UseAlternateTheme
    ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
    : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme
)
</script>

<script lang="ts">
export default defineComponent({ name: 'Skeleton' })
</script>

<style lang="scss">
@use '~/assets/variables';

$flex: var(--flex, auto);
$width: var(--width, 100%);
$height: var(--height, 35px);
$spaceBetween: var(--spaceBetween, 16px);

.v-skeleton-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  min-height: $height;

  animation: appear 0.5s ease;

  .v-skeleton-wrapper-item {
    flex: $flex;
    max-width: $width;

    &__more_than_one {
      padding: 0 $spaceBetween $spaceBetween 0;
    }
  }
}

.v-skeleton-loader {
  width: 100%;
  height: $height;
  display: block;
  border-radius: variables.$BorderRadiusInput;

  &-dark {
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15) 25%,
        rgba(0, 0, 0, 0.25) 50%,
        rgba(0, 0, 0, 0.15) 75%,
        rgba(0, 0, 0, 0) 100%
      ),
      #2c2c2c;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1.5s infinite linear;
  }

  &-light {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.15) 25%,
        rgba(255, 255, 255, 0.25) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        rgba(255, 255, 255, 0) 100%
      ),
      #d3d3d3;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1.5s infinite linear;
  }
}

@keyframes shine {
  from {
    background-position: -75px;
  }
  to {
    background-position: 115% 0;
  }
}
</style>
