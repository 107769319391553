<template>
  <ClientOnly>
    <template #fallback>
      <div
        v-for="(item, index) of appConfig.Products.filter((product) => {
          if (
            menuStore.ActivityMapLoaded && //we have no info
            (menuStore.BlockedMap.ProductIds.includes(product.ID) || //product is not blocked
              menuStore.NonActiveMap.ProductIds.includes(product.ID)) //we know that it's currently working
          ) {
            return false
          }

          if (menuStore.CurrentSubgroup && menuStore.CurrentSubgroup.IsCommonGroup) {
            const groupsIds = new Set(
              (appConfig.Groups.filter((group) => group.ParentGroupID === CurrentGroupId) ?? []).map(
                (group) => group.ID
              )
            )

            return groupsIds.has(product.GroupID)
          } else {
            return product.GroupID === (CurrentSubgroupId ?? CurrentGroupId)
          }
        })"
        :key="`menu-list-prerender-${index}`"
      >
        <menu-product-in-list-item-card-by-type
          :key="`list-fanuidhol-${item.ID}`"
          :product="item"
        />
      </div>
    </template>
    <div
      v-if="!menuList || menuList.length === 0"
      class="v-d-flex v-flex-row v-flex-100 v-menu-title v-error-color v-title"
    >
      <span
        v-if="FilterTags.length > 0 || FilterStickers.length > 0"
        v-html="translate('productInListPage.notFoundFilter')"
      />
      <span
        v-else
        v-html="translate('productInListPage.notFound')"
      />
    </div>
    <common-cards-flex-mesh
      v-else
      :class-name="`v-card-product-${appConfig.VueSettingsPreRun.MenuItemsInRow}`"
      :items="menuList"
      :max-items="appConfig.VueSettingsPreRun.MenuItemsInRow"
      :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
      v-model:adaptive-items="adaptiveItems"
    >
      <template #indexedItem="{ item, index }">
        <menu-product-in-list-banner-card
          v-if="item.Banners && item.Banners.length > 0"
          :banners="item.Banners"
        />
        <menu-product-in-list-item-card-by-type
          v-else-if="item.Product"
          :key="`list-fanuidhol-${item.Product.ID}`"
          :disable-lazy="index <= appConfig.VueSettingsPreRun.MenuItemsInRow"
          :product="item.Product"
        />
      </template>
    </common-cards-flex-mesh>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { BannerInList, ItemInList } from '~types/menuStore'

import { useCommon } from '@arora/common'

import { Guid } from '~api/consts'

const { applyProductsFilter } = useProduct()
const { checkGuidEnable } = useCommon()
const appConfig = useAppConfig()
const menuStore = useMenuStore()
const { ActivityMapLoaded, CurrentGroupId, CurrentSubgroupId, FilterStickers, FilterTags, Sorting } =
  storeToRefs(menuStore)
const adaptiveItems = ref<number>(appConfig.VueSettingsPreRun.MenuItemsInRow)
const router = useRouter()

menuStore.CurrentItemsInRow = adaptiveItems.value

const { translate } = useI18nSanitized()
watch(
  () => adaptiveItems.value,
  () => {
    menuStore.CurrentItemsInRow = adaptiveItems.value
  }
)

const { data: menuList, refresh } = await useAsyncData('menuList', () => refreshProducts(), {
  dedupe: 'defer',
  watch: [ActivityMapLoaded, CurrentGroupId, CurrentSubgroupId, FilterStickers, FilterTags, Sorting]
})

watch(FilterStickers, async () => await refresh(), { deep: true })

watch(FilterTags, async () => await refresh(), { deep: true })

watch(
  () => ActivityMapLoaded.value,
  () =>
    setTimeout(() => {
      refresh()
    }, 200),
  { immediate: true }
)

onMounted(() => {
  refresh()
})

router.afterEach(() => refresh())

async function refreshProducts(): Promise<ItemInList[]> {
  if (Guid.IsNullOrEmpty(CurrentSubgroupId.value ?? CurrentGroupId.value)) {
    return [] as ItemInList[]
  }
  const menuListItems = [] as ItemInList[]

  if (menuStore.CurrentSubgroup && menuStore.CurrentSubgroup.IsCommonGroup) {
    const groupsIds = new Set(
      (appConfig.Groups.filter((group) => group.ParentGroupID === CurrentGroupId.value) ?? []).map(
        (group) => group.ID
      )
    )

    for (const product of appConfig.Products) {
      if (groupsIds.has(product.GroupID)) menuListItems.push({ Product: product })
    }
  } else {
    for (const product of appConfig.Products) {
      if (product.GroupID === (CurrentSubgroupId.value ?? CurrentGroupId.value))
        menuListItems.push({ Product: product })
    }
  }

  for (const banner of appConfig.Banners) {
    if (
      checkGuidEnable(banner.Active) &&
      (banner.GroupIDs.includes(CurrentSubgroupId.value ?? CurrentGroupId.value) ||
        banner.ShowInEveryGroup)
    ) {
      if (banner.PositionInGroup >= menuListItems.length) {
        menuListItems.push({ Banners: [banner] })
      } else if ((menuListItems[banner.PositionInGroup].Banners ?? []).length > 0) {
        menuListItems[banner.PositionInGroup].Banners!.push(banner)
      } else {
        const result = [] as BannerInList[]
        result.push(banner)
        menuListItems.splice(banner.PositionInGroup, 0, { Banners: result })
      }
    }
  }

  if (menuListItems) {
    const items = await menuStore.applyFiltersAndSorting(menuListItems)

    if (items.length === 0 && menuListItems.length > 0)
      console.error('No products available due to filters and sorting')

    return items.filter((item) => {
      if (item.Product) {
        return applyProductsFilter(item.Product)
      }
      return true //do not filter banners
    })
  }

  return [] as ItemInList[]
}
</script>
