import type { ClientState } from '~types/clientStore'
import type { IMetric, RestaurantSettings } from '~types/restaurantStore'

import { type GUID, RegionalSettings } from '@arora/common'

export default class YandexMetric implements IMetric {
  yandexMetricsOrderGoalId: string
  yandexMetricsSendAddToCart: boolean
  yandexMetricsSendOrder: boolean
  yandexMetricsSendProductView: boolean
  yandexMetricsSendRemoveFromCart: boolean

  constructor(data: RestaurantSettings) {
    this.yandexMetricsSendOrder = data.YandexMetricsSendOrder
    this.yandexMetricsSendAddToCart = data.YandexMetricsSendAddToCart
    this.yandexMetricsSendProductView = data.YandexMetricsSendProductView
    this.yandexMetricsSendRemoveFromCart = data.YandexMetricsSendRemoveFromCart
    this.yandexMetricsOrderGoalId = data.YandexMetricsOrderGoalId

    window.dataLayer = window.dataLayer || []
    let expires = '',
      storage = ''
    try {
      storage = window.localStorage.getItem('windowDataLayer') ?? ''
      expires = window.localStorage.getItem('windowDataLayerExpires') ?? ''
    } catch (error) {
      // fallback in case if local storage is disabled
      // change that fallback if there's something meaningful we can do
      console.error('store request error', error)

      return
    }
    let millis = -1
    if (expires.length > 0) {
      millis = Date.now() - Number.parseInt(expires)
    }

    if (millis === -1 || millis > 1000 * 60 * 60) {
      window.localStorage.removeItem('windowDataLayer')
      window.localStorage.removeItem('windowDataLayerExpires')
    }

    if (storage.length > 0) {
      const dataLayer: YandexDataLayer[] = JSON.parse(storage)
      const totalLayers = [...dataLayer, ...window.dataLayer]

      window.dataLayer = totalLayers.filter(
        (item: YandexDataLayer, pos: number) => totalLayers.indexOf(item) === pos
      ) //dedupe
    }
  }

  saveDataLayer(): void {
    try {
      window.localStorage.setItem('windowDataLayer', JSON.stringify(window.dataLayer))
      window.localStorage.setItem('windowDataLayerExpires', Date.now().toString())
    } catch (_error) {
      // do nothing because we can't do anything without a local storage here
    }
  }

  async sendMetricAddToCart(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    PriceModified: number,
    Count: number,
    OptionID: GUID
  ): Promise<void> {
    if (!this.yandexMetricsSendAddToCart) return

    window.dataLayer = window.dataLayer || []

    const appConfig = useAppConfig()
    const clientStore = useClientStore()
    const yandexProducts: YandexProduct[] = []

    const clientState: ClientState | null = clientStore.ClientState.data

    if (clientState && clientState.Cart) {
      yandexProducts.push({
        brand: '',
        category: GroupID,
        id: ProductID,
        name: Name,
        price: PriceModified,
        quantity: Count,
        variant: OptionID
      })
    }

    const newLayer: YandexDataLayer = {
      ecommerce: {
        add: {
          products: yandexProducts
        },
        currencyCode: RegionalSettings.get(appConfig.VueSettingsPreRun.Currency)?.CurrencyISO ?? '?'
      }
    }

    window.dataLayer.push(newLayer)
    await this.saveDataLayer()
  }

  async sendMetricAfterOrder(orderId: GUID, totalAmountToPay: number): Promise<void> {
    if (!this.yandexMetricsSendOrder) return

    window.dataLayer = window.dataLayer || []

    const appConfig = useAppConfig()
    const clientStore = useClientStore()
    const yandexProducts: YandexProduct[] = []
    let coupon = ''

    const clientState: ClientState | null = clientStore.ClientState.data

    if (clientState && clientState.Cart) {
      coupon = clientState.Promo?.PromoCode ?? ''
      for (const cartItem of clientState.Cart.Content) {
        yandexProducts.push({
          brand: '',
          category: cartItem.Product.GroupID,
          id: cartItem.ProductID,
          name: cartItem.Name,
          price:
            cartItem.PriceInPoints === null || cartItem.PriceInPoints.Amount === 0
              ? cartItem.Price.WithDiscount
              : cartItem.PriceInPoints.Amount,
          quantity: cartItem.Count,
          variant: cartItem.OptionID
        })
      }
    }

    const newLayer: YandexDataLayer = {
      ecommerce: {
        currencyCode: RegionalSettings.get(appConfig.VueSettingsPreRun.Currency)?.CurrencyISO ?? '?',
        purchase: {
          actionField: {
            coupon: coupon,
            // eslint-disable-next-line camelcase
            goal_id: this.yandexMetricsOrderGoalId,
            id: orderId,
            revenue: totalAmountToPay.toString()
          },
          products: yandexProducts
        }
      }
    }

    window.dataLayer.push(newLayer)
    await this.saveDataLayer()
  }

  sendMetricBeforeOrder(): Promise<void> {
    return Promise.resolve(undefined)
  }

  sendMetricCartView(): Promise<void> {
    return Promise.resolve(undefined)
  }

  async sendMetricRemoveFromCart(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    Count: number
  ): Promise<void> {
    if (!this.yandexMetricsSendRemoveFromCart) return

    window.dataLayer = window.dataLayer || []

    const appConfig = useAppConfig()
    const clientStore = useClientStore()
    const yandexProducts: YandexProduct[] = []

    const clientState: ClientState | null = clientStore.ClientState.data

    if (clientState && clientState.Cart) {
      yandexProducts.push({
        brand: '',
        category: GroupID,
        id: ProductID,
        name: Name,
        price: null,
        quantity: Count,
        variant: null
      })
    }

    const newLayer: YandexDataLayer = {
      ecommerce: {
        currencyCode: RegionalSettings.get(appConfig.VueSettingsPreRun.Currency)?.CurrencyISO ?? '?',
        remove: {
          products: yandexProducts
        }
      }
    }

    window.dataLayer.push(newLayer)
    await this.saveDataLayer()
  }

  async SendMetricsProductView(
    GroupID: GUID,
    ProductID: GUID,
    Name: string,
    PriceModified: number,
    Count: number,
    OptionID: GUID | undefined
  ): Promise<void> {
    if (!this.yandexMetricsSendProductView) return

    window.dataLayer = window.dataLayer || []

    const appConfig = useAppConfig()
    const clientStore = useClientStore()
    const yandexProducts: YandexProduct[] = []

    const clientState: ClientState | null = clientStore.ClientState.data

    if (clientState && clientState.Cart) {
      yandexProducts.push({
        brand: '',
        category: GroupID,
        id: ProductID,
        name: Name,
        price: PriceModified,
        quantity: Count,
        variant: OptionID
      })
    }

    const newLayer: YandexDataLayer = {
      ecommerce: {
        currencyCode: RegionalSettings.get(appConfig.VueSettingsPreRun.Currency)?.CurrencyISO ?? '?',
        detail: {
          products: yandexProducts
        }
      }
    }

    window.dataLayer.push(newLayer)
    await this.saveDataLayer()
  }
}

export type YandexDataLayer = {
  ecommerce: YandexEcommerce
}

type YandexEcommerce = {
  currencyCode: string
  purchase?: YandexPurchase
  add?: YandexAdd
  remove?: YandexRemove
  detail?: YandexDetail
}

type YandexPurchase = {
  actionField: YandexActionField
  products: YandexProduct[]
}
type YandexAdd = {
  products: YandexProduct[]
}
type YandexDetail = {
  products: YandexProduct[]
}
type YandexRemove = {
  products: YandexProduct[]
}

type YandexActionField = {
  id: string
  goal_id: string
  coupon: string
  revenue: string
}

type YandexProduct = {
  id: string
  name: string
  price: number | null
  brand: string
  category: string
  variant: string | null | undefined
  quantity: number
}
