<i18n>
ru:
  notSelected: Не выбрано
  yourCity: 'Ваш город:'
  yourDistrict: 'Ваш район:'
  yourRest: 'Ближайший к Вам ресторан:'
  yourTerminal: 'Терминал доставки:'
ua:
  notSelected: Не обраний
  yourCity: 'Ваше місто:'
  yourDistrict: 'Ваш район:'
  yourRest: 'Найближчий до вас ресторан:'
  yourTerminal: 'Термінал доставки:'
us:
  notSelected: Not selected
  yourCity: 'Your city:'
  yourDistrict: 'ZIP code:'
  yourRest: 'Restaurant closest to you:'
  yourTerminal: 'Delivery terminal:'
</i18n>

<template>
  <div
    v-if="addressStore.ActiveCities.length > 1"
    class="v-row v-mb-xs v-align-items-center"
  >
    <div :class="[deliveryAddress ? 'v-col-9' : 'v-col-12']">
      <arora-select
        id="order_city"
        default-option
        required
        v-form-validator="{
          Form: form,
          Value: cityId !== Guid.Empty ? '#' : '',
          Required: true,
          Validations: []
        }"
        :default-option-disabled="false"
        :default-option-text="translate('deliveryCityDistrict.notSelected')"
        :default-option-value="Guid.Empty"
        :label="translate('deliveryCityDistrict.yourCity')"
        :options="addressStore.ActiveCities.map((item) => item.ID)"
        data-test-id="cart-select-address"
        v-model:selected="cityId"
      >
        <template #index="options: { index: number }">
          <option
            :value="addressStore.ActiveCities[options.index].ID"
            v-html="sanitize(addressStore.ActiveCities[options.index].Title)"
          />
        </template>
      </arora-select>
    </div>
  </div>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="districts.length > 1"
      class="v-row v-mb-xs v-align-items-center"
    >
      <div :class="[deliveryAddress ? 'v-col-9' : 'v-col-12']">
        <arora-select
          id="order_district"
          required
          :label="translate('deliveryCityDistrict.yourDistrict')"
          :options="districts.map((item) => item.ID)"
          data-test-id="cart-select-address"
          v-model:selected="districtId"
        >
          <template #index="options: { index: number }">
            <option
              :value="districts[options.index].ID"
              v-html="sanitize(districts[options.index].Title)"
            />
          </template>
        </arora-select>
      </div>
    </div>
  </transition>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="departments.length > 1"
      class="v-row v-mb-xs v-align-items-center"
    >
      <div :class="[deliveryAddress ? 'v-col-9' : 'v-col-12']">
        <arora-select
          id="order_department"
          required
          :label="translate('deliveryCityDistrict.yourRest')"
          :options="departments.map((item) => item.ID)"
          data-test-id="cart-select-address"
          v-model:selected="departmentId"
        >
          <template #index="options: { index: number }">
            <option
              :value="departments[options.index].ID"
              v-html="sanitize(departments[options.index].Name)"
            />
          </template>
        </arora-select>
      </div>
    </div>
  </transition>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="terminals.length > 1"
      class="v-row v-mb-xs v-align-items-center"
    >
      <div :class="[deliveryAddress ? 'v-col-9' : 'v-col-12']">
        <arora-select
          id="order_terminal"
          required
          :label="translate('deliveryCityDistrict.yourTerminal')"
          :options="terminals.map((item) => item.ID)"
          data-test-id="cart-select-address"
          v-model:selected="terminalId"
        >
          <template #index="options: { index: number }">
            <option
              :value="terminals[options.index].ID"
              v-html="sanitize(terminals[options.index].Name)"
            />
          </template>
        </arora-select>
      </div>
    </div>
  </transition>

  <!--#region DEFAULT VALUES-->
  <!--suppress XmlDuplicatedId -->
  <select
    v-if="addressStore.ActiveCities.length === 1"
    id="order_city"
    class="city-select"
    :aria-label="translate('deliveryCityDistrict.yourCity')"
    data-test-id="cart-select-address"
    name="ship_to_city"
    v-show="false"
  >
    <option
      selected
      :value="cityId"
    >
      {{ cityId }}
    </option>
  </select>
  <!--suppress XmlDuplicatedId -->
  <select
    v-if="terminals.length === 1"
    id="order_terminal-shipment"
    class="terminalSelect"
    :aria-label="translate('deliveryCityDistrict.yourTerminal')"
    data-test-id="cart-select-address"
    name="client_to_terminal"
    v-show="false"
  >
    <option
      selected
      :value="terminalId"
    >
      {{ terminalId }}
    </option>
  </select>
  <!--suppress XmlDuplicatedId -->
  <select
    v-if="departments.length === 1"
    id="order_organization-shipment"
    class="organizationSelect"
    :aria-label="translate('deliveryCityDistrict.yourRest')"
    data-test-id="cart-select-address"
    name="client_to_organization"
    v-show="false"
  >
    <option
      selected
      :value="departmentId"
    >
      {{ departmentId }}
    </option>
  </select>

  <!--suppress XmlDuplicatedId -->
  <select
    v-if="districts.length === 1"
    id="order_district"
    class="district-select"
    :aria-label="translate('deliveryCityDistrict.yourDistrict')"
    data-test-id="cart-select-address"
    name="ship_to_district"
    v-show="false"
  >
    <option
      selected
      :value="districtId"
    >
      {{ districtId }}
    </option>
  </select>
  <!--#endregion -->

  <cart-error-notifier
    hide-non-input-errors
    show-only-info-message
  />
</template>

<script setup lang="ts">
import type { AccountOverridableAddress } from '~types/accountStore'
import type { CityOrganisation, Department, DistrictsPrice, Terminal } from '~types/addressStore'
import type { HasValidatorForm } from '~types/props'

import { type GUID, vFormValidator } from '@arora/common'

import { Guid } from '~api/consts'

const {
  deliveryAddress,
  form = 'cart',
  saveDeliveryAddress
} = defineProps<AccountOverridableAddress & HasValidatorForm>()

onMounted(async () => {
  if (import.meta.client) {
    if (addressStore.ActiveCities.length === 0) {
      await addressStore.initCities()
    } else if (addressStore.ActiveCities.length === 1) {
      cityId.value = addressStore.ActiveCities[0].ID
    }
  }
})

const { sanitize, translate } = useI18nSanitized()
const addressStore = useAddressStore()
const clientStore = useClientStore()

const defaultAddress = {
  addressType: '',
  apartment: '',
  areaID: Guid.Empty,
  areaName: '',
  building: '',
  cityID: Guid.Empty,
  cityName: '',
  closerDepartmentID: '',
  closerTerminalID: Guid.Empty,
  comment: '',
  corpus: '',
  doorway: '',
  floor: '',
  house: '',
  id: Guid.Empty,
  intercom: '',
  intercome: '',
  orderDeliveryType: 0,
  street: '',
  zipCode: ''
}

/*#region values that may be preselected*/
const cityId = computed<GUID>({
  get: () => {
    if (deliveryAddress) return deliveryAddress.CityID ?? Guid.Empty

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.CityID ?? Guid.Empty
  },
  set: (cityId) => {
    const city = addressStore.ActiveCities.find((city: CityOrganisation) => {
      return city.ID === cityId
    })

    let department: Department | null = null,
      district: DistrictsPrice | null = null,
      terminalId: GUID | undefined

    if (city) {
      const districtsPrices =
        city?.DistrictsPrices.filter((item: DistrictsPrice) => {
          return item.Active
        }) ?? []
      district = districtsPrices.length > 0 ? districtsPrices[0] : null

      if (district) {
        const departments =
          district.Departments?.filter((item: Department) => {
            return item.Active
          }) ?? []
        department = departments.length > 0 ? departments[0] : null

        if (department) {
          const terminals =
            department.Terminals?.filter((item: Terminal) => {
              return item.Active
            }) ?? []

          terminalId = terminals?.length > 0 ? terminals[0].ID : Guid.Empty
        } //end if department
      } //end if district
    } //end if city
    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        cityId: cityId,
        departmentId: department?.ID ?? Guid.Empty,
        districtId: district?.ID ?? Guid.Empty,
        Street: '',
        terminalId: terminalId
      })
    } else if (cityId === clientStore.ClientState?.data?.SelectedDeliveryAddress?.CityID) {
      clientStore.updateDeliveryAddressSingle({
        cityId: cityId,
        departmentId: department?.ID ?? Guid.Empty,
        districtId: district?.ID ?? Guid.Empty,
        terminalId: terminalId
      })
    } else {
      clientStore.updateDeliveryAddressSingle({
        ...defaultAddress,
        cityId: cityId,
        departmentId: department?.ID ?? Guid.Empty,
        districtId: district?.ID ?? Guid.Empty,
        terminalId: terminalId
      })
    }
  }
})
const districtId = computed<GUID>({
  get: () => {
    if (deliveryAddress) return deliveryAddress.AreaID ?? Guid.Empty

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.DistrictID ?? Guid.Empty
  },
  set: (districtId) => {
    const district = selectedCity.value?.DistrictsPrices.find((district: DistrictsPrice) => {
      return district.ID === districtId
    })
    let department: Department | null = null,
      terminalId: GUID | undefined

    if (district) {
      const departments =
        district.Departments?.filter((item: Department) => {
          return item.Active
        }) ?? []
      department = departments.length > 0 ? departments[0] : null

      if (department) {
        const terminals =
          department.Terminals?.filter((item: Terminal) => {
            return item.Active
          }) ?? []

        terminalId = terminals?.length > 0 ? terminals[0].ID : Guid.Empty
      } //end if department
    } //end if district

    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        departmentId: department?.ID ?? Guid.Empty,
        districtId: district?.ID ?? Guid.Empty,
        Street: '',
        terminalId: terminalId
      })
    } else {
      clientStore.updateDeliveryAddressSingle({
        departmentId: department?.ID ?? Guid.Empty,
        districtId: district?.ID ?? Guid.Empty,
        terminalId: terminalId
      })
    }
  }
})

const departmentId = computed<GUID>({
  get: () => {
    if (deliveryAddress) return (deliveryAddress.CloserDepartmentID as GUID | null) ?? Guid.Empty

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.DepartmentID ?? Guid.Empty
  },
  set: (departmentId) => {
    const department = selectedDistrict.value?.Departments.find((department: Department) => {
      return department.ID === departmentId
    })

    let terminalId: GUID | undefined

    if (department) {
      const terminals =
        department.Terminals?.filter((item: Terminal) => {
          return item.Active
        }) ?? []

      terminalId = terminals?.length > 0 ? terminals[0].ID : Guid.Empty
    } //end if department

    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        departmentId: department?.ID ?? Guid.Empty,
        Street: '',
        terminalId: terminalId
      })
    } else {
      clientStore.updateDeliveryAddressSingle({
        departmentId: department?.ID ?? Guid.Empty,
        terminalId: terminalId
      })
    }
  }
})

const terminalId = computed<GUID>({
  get: () => {
    if (deliveryAddress) return deliveryAddress.CloserTerminalID ?? Guid.Empty

    return clientStore.ClientState?.data?.SelectedDeliveryAddress?.TerminalID ?? Guid.Empty
  },
  set: (terminalId) => {
    if (saveDeliveryAddress) {
      saveDeliveryAddress({
        Street: '',
        terminalId: terminalId
      })
    } else {
      clientStore.updateDeliveryAddressSingle({
        terminalId: terminalId
      })
    }
  }
})
/*#endregion*/

const selectedCity = computed<CityOrganisation | undefined>(() => {
  const cityObject: CityOrganisation | undefined = addressStore.ActiveCities.find(
    (city: CityOrganisation) => {
      return city.ID === cityId.value
    }
  )
  if (saveDeliveryAddress) {
    saveDeliveryAddress({
      city: cityObject?.Title
    })
  }

  return cityObject
})

const selectedDistrict = computed<DistrictsPrice | undefined>(() => {
  const districtObject: DistrictsPrice | undefined = districts.value.find((district: DistrictsPrice) => {
    return district.ID === districtId.value
  })
  if (saveDeliveryAddress && !Guid.IsNullOrEmpty(districtId.value)) {
    saveDeliveryAddress({
      district: districtObject?.Title
    })
  }

  return districtObject
})

const selectedDepartment = computed<Department | undefined>(() => {
  return departments.value.find((dep: Department) => {
    return dep.ID === departmentId.value
  })
})

const districts = computed<DistrictsPrice[]>(() => {
  return (
    selectedCity.value?.DistrictsPrices?.filter((item: DistrictsPrice) => {
      return item.Active
    }) ?? []
  )
})

const departments = computed<Department[]>(() => {
  return (
    selectedDistrict.value?.Departments?.filter((item: Department) => {
      return item.Active
    }) ?? []
  )
})

const terminals = computed<Terminal[]>(() => {
  return (
    selectedDepartment.value?.Terminals?.filter((item: Terminal) => {
      return item.Active
    }) ?? []
  )
})

watch(
  () => addressStore.CityOrganisation.data,
  (newCities) => {
    if (newCities?.length === 1) {
      cityId.value = newCities[0].ID
    }
  }
)
</script>
