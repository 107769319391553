export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    if (
      error &&
      typeof error === 'object' &&
      'message' in error &&
      error.message &&
      error.message.toString().includes('Failed to fetch dynamically imported module')
    ) {
      window.location.reload()
    } else {
      const { showInternalError } = usePopupStore()

      showInternalError(error as Error)
      console.error(error)
    }
  }
})
