<i18n>
ru:
  download: Скачать
  title: Приложение ресторана
ua:
  download: Завантажити
  title: Додаток ресторану
us:
  download: Download
  title: Restaurant app
</i18n>

<template>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="!mobileAppInvitation && hasLink && isAppleOrAndroidDevice"
      class="v-mobile-app-invitation"
    >
      <div class="v-d-flex v-align-items-center">
        <icon-general-cross
          class="v-mobile-app-invitation--close-button"
          @click="close"
        />
        <div class="v-mobile-app-invitation--img">
          <arora-nuxt-image
            :alt="translate('mobileAppInvitation.download')"
            :image="appConfig.VueSettingsPreRun.HeaderAppDownloadLogo"
          />
        </div>
        <span
          class="v-mobile-app-invitation--title"
          v-html="translate('mobileAppInvitation.title')"
        />
        <div class="v-mobile-app-invitation--button">
          <arora-button
            class-name="v-btn v-btn-secondary v-btn-md v-w-100"
            ignore-settings
            :label="translate('mobileAppInvitation.download')"
            @click="openPrompt"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
const mobileAppInvitation = useCookie('mobileAppInvitation')

const { isAppleDevice, isAppleOrAndroidDevice } = useCommon()

const hasLink = computed<boolean>(() =>
  isAppleDevice.value ? appConfig.AppsPreRun.AppStoreEnabled : hasAnyAndroidStoreEnabled.value
)

const {
  androidStoresLink,
  appStoreLink,
  googlePlayLinkWithUTM,
  hasAnyAndroidStoreEnabled,
  huaweiAppGalleryLinkWithUTM,
  ruStoreLink
} = useAppsLinks()

function close(): void {
  mobileAppInvitation.value = 'true'
}

function openPrompt(): void {
  if (isAppleDevice.value) {
    if (appStoreLink.value)
      navigateTo(appStoreLink.value, {
        external: true
      })
  } else if (androidStoresLink.value)
    navigateTo(androidStoresLink.value, {
      external: true
    })
  else if (googlePlayLinkWithUTM.value)
    navigateTo(googlePlayLinkWithUTM.value, {
      external: true
    })
  else if (huaweiAppGalleryLinkWithUTM.value)
    navigateTo(huaweiAppGalleryLinkWithUTM.value, {
      external: true
    })
  else if (ruStoreLink.value)
    navigateTo(ruStoreLink.value, {
      external: true
    })
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-mobile-app-invitation {
  min-height: 50px;
  max-width: 100%;
  padding: 1.1rem 1.5rem 1.1rem 1.1rem;
  box-shadow: variables.$FloatShadow;
  background: variables.$BodyBackground;
  color: variables.$BodyTextColor;

  &--img {
    max-width: 45px;
    flex: 0 0 45px;

    margin-right: 10px;
    border-radius: variables.$BorderRadius;
    overflow: hidden;
  }

  &--title {
    opacity: 0.5;

    max-width: calc(100% - 150px);
    flex: 0 0 calc(100% - 150px);
  }

  &--button {
    flex: 0 0 85px;
    width: 85px;
  }

  &--close-button {
    min-width: 18px;
    width: auto;
    height: 18px;
    margin-right: 5px;

    path {
      stroke: variables.$BodyTextColor;
    }
  }
}
</style>
