<template>
  <div class="v-menu-ingredient">
    <div class="v-menu-ingredient__image">
      <arora-nuxt-image
        :image="
          imageExists(ingredient.ProductMobileImage)
            ? ingredient.ProductMobileImage
            : ingredient.SmallImage
        "
        :alt="ingredient.Name"
        :height="125"
        :image-type="imageExists(ingredient.ProductMobileImage) ? 'ProductMobile' : 'ProductSmall'"
        :width="125"
      />
    </div>
    <div class="v-menu-ingredient__info v-ml-xs">
      <div class="v-menu-ingredient__upper-part">
        <span
          class="v-menu-ingredient__upper-part-title v-title"
          v-html="sanitize(getIngredientOptionIfExist(productInList, ingredient).Name)"
        />
        <span
          v-if="!hideWeight"
          class="v-menu-ingredient__upper-part-weight"
        >
          <i18n-t
            keypath="basePage.measure"
            scope="global"
          >
            <template #measure>
              <common-quantity-measure
                :quantity="getIngredientOptionIfExist(productInList, ingredient).Weight"
              />
            </template>
          </i18n-t>
        </span>
      </div>
      <div
        v-if="
          !stringIsNullOrWhitespace(
            'ProductDescription' in getIngredientOptionIfExist(productInList, ingredient)
              ? (getIngredientOptionIfExist(productInList, ingredient) as Option).ProductDescription
              : ingredient.Description
          )
        "
        class="v-menu-ingredient__description"
      >
        <span
          class="v-line-clamp-menu"
          v-html="
            sanitize(
              'ProductDescription' in getIngredientOptionIfExist(productInList, ingredient)
                ? (getIngredientOptionIfExist(productInList, ingredient) as Option).ProductDescription
                : ingredient.Description
            )
          "
        />
      </div>

      <div
        v-if="
          !menuStore.NonActiveMap.ProductIds.includes(
            getIngredientOptionIfExist(productInList, ingredient).ID
          )
        "
        class="v-menu-ingredient__lower_part"
      >
        <div class="v-menu-ingredient__lower_part-price">
          <common-currency
            v-if="ingredient.PriceInPoints > 0"
            show-points
            :amount="
              Object.keys(ingredient.Options ?? []).length > 0
                ? ingredient.PriceInPoints +
                  (Object.values(ingredient.Options ?? [])
                    .flat()
                    .find((o) => o.ID === productInList.IngredientsIDsWithOptionID[ingredient.ID])
                    ?.PriceInPoints ?? 0)
                : ingredient.PriceInPoints
            "
          />
          <common-currency
            v-else
            :amount="
              Object.keys(ingredient.Options ?? []).length > 0
                ? ingredient.PriceModified +
                  (Object.values(ingredient.Options ?? [])
                    .flat()
                    .find((o) => o.ID === productInList.IngredientsIDsWithOptionID[ingredient.ID])
                    ?.Price ?? 0)
                : ingredient.PriceModified
            "
          />
        </div>
        <div class="v-menu-ingredient__lower_part-link">
          <arora-nuxt-link
            v-if="ingredient.Active"
            class="v-primary-color v-d-flex v-align-items-center v-justify-content-end"
            :href="ingredient.Link"
            :label="translate('description.details')"
          >
            <span
              class="v-menu-ingredient__lower_part-link-text"
              v-html="translate('description.details')"
            />
            <icon-general-arrow-next class="v-menu-ingredient__lower_part-link-arrow-next v-ml-xs" />
          </arora-nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Option, ProductInList } from '~types/menuStore'

import { useCommon } from '@arora/common'

defineProps<{
  productInList: ProductInList
  ingredient: ProductInList
  hideWeight?: boolean
}>()

const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { imageExists } = useImageInfo()
const { getIngredientOptionIfExist } = useProduct()

const menuStore = useMenuStore()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-menu-ingredient {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: stretch;
  min-width: 250px;
  max-width: 450px;
  min-height: 70px;
  height: 100%;

  &__image {
    flex: 0 0 35%;
    display: flex;
    align-items: center;
    border-radius: variables.$BorderRadius;
  }

  &__info {
    flex: 0 1 65%;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__description {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  &__upper-part {
    &-title {
      font-size: 1rem;
      font-weight: 600;
      margin-right: 5px;
    }

    &-weight {
      font-size: 0.85rem;
    }
  }

  &__lower_part {
    display: flex;
    gap: 10px;
    flex-direction: row;
    font-size: 1rem;
    margin-top: auto;

    &-price {
      flex: 0 0 40%;
      max-width: 40%;
    }

    &-link {
      flex: 0 0 calc(60% - 10px);
      max-width: calc(60% - 10px);
      text-align: right;
    }
  }
}
</style>
