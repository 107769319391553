import { type ISO, RegionalSettings } from '@arora/common'

import { Mask } from 'maska'

type phoneNumberComposable = {
  phoneBeautified: (
    phone: string | undefined,
    mode?: 'header' | 'other' | 'user',
    useCustomCode?: boolean
  ) => string
  phoneCode: ComputedRef<string>
  phoneLength: ComputedRef<number>
  phoneMaskPlaceholder: ComputedRef<string>
  phoneMask: ComputedRef<string>
}

export default function usePhoneNumber(): phoneNumberComposable {
  const appConfig = useAppConfig()

  const phoneLength = computed<number>(() => {
    return (phoneMask.value.replace(/[0-9]/g, '#').match(/#/g) || []).length
  })
  const phoneMask = computed<string>(() => {
    return `+${phoneCode.value} ${appConfig.VueSettingsPreRun.PhoneMaskOverride.length > 0 ? appConfig.VueSettingsPreRun.PhoneMaskOverride : '(###) ###-##-##'}`
  })
  const phoneMaskPlaceholder = computed<string>(() => {
    return `+${phoneCode.value}  ${phoneMask.value.replaceAll('#', '  ')}`
  })
  const phoneCode = computed<string>(() => {
    const override = appConfig.VueSettingsPreRun.PhoneCodeOverride
    if (override.length > 0) return override

    //every possible code for every country at the moment
    return RegionalSettings.get(appConfig.VueSettingsPreRun.PhoneCountry)?.PhoneCode?.toString() ?? '7'
  })

  function phoneBeautified(
    phone: string | undefined,
    mode: 'header' | 'other' | 'user' = 'user',
    useCustomCode = false
  ): string {
    if (!phone) {
      return ''
    }

    let phoneLocal = phone.trim()
    let phoneCodeLocal = ''
    let phoneMask = ''

    switch (mode) {
      case 'header':
        phoneCodeLocal = appConfig.VueSettingsPreRun.PhoneCodeHeaderOverride
        phoneMask = appConfig.VueSettingsPreRun.PhoneMaskHeaderOverride
        break
      case 'other':
        phoneCodeLocal = appConfig.VueSettingsPreRun.PhoneCodeOtherOverride
        phoneMask = appConfig.VueSettingsPreRun.PhoneMaskOtherOverride
        break
      case 'user':
        phoneCodeLocal = appConfig.VueSettingsPreRun.PhoneCodeOverride
        phoneMask = appConfig.VueSettingsPreRun.PhoneMaskOverride
        break
    }

    if (!phoneCodeLocal || phoneCodeLocal.length === 0 || (phoneCodeLocal === '8' && !useCustomCode)) {
      phoneCodeLocal =
        RegionalSettings.get(appConfig.VueSettingsPreRun.PhoneCountry as ISO)?.PhoneCode?.toString() ??
        '7'
    }

    if (phoneCodeLocal !== '8' && !phoneCodeLocal.includes('+')) {
      phoneCodeLocal = `+${phoneCodeLocal}`
    }

    if (phoneCodeLocal !== '8') {
      phoneLocal = phone.trim().startsWith('8') ? `7${phone.substring(1)}` : phone
    }

    const mask = new Mask({
      mask: `${phoneCodeLocal} ${phoneMask && phoneMask.length > 0 ? phoneMask : '(###) ###-##-##'}`
    })

    if (mask.completed(phoneLocal)) {
      return mask.masked(phoneLocal)
    }

    return phone
  }

  return {
    phoneBeautified,
    phoneCode,
    phoneLength,
    phoneMask,
    phoneMaskPlaceholder
  }
}
