<i18n>
ru:
  bonusAccrual: 'Баллов к начислению:'
  bonusWithdraw: 'Баллов к списанию:'
  cartSum: 'Сумма заказа:'
  delivery: 'Доставка:'
  discount: 'Скидка:'
  emptyCart: Корзина пуста. Добавьте что-нибудь.....
  notLoggedInText: 'Чтобы получить баллы за этот заказ, сначала войдите в '
  notLoggedInLink: Личный кабинет.
  priorityCooking: 'Приоритетное приготовление: '
  summaryOpen: Подробнее о расчётах
  serviceFee: 'Сервисный сбор:'
  tax: 'Налог:'
  tip: 'Чаевые:'
  totalPay: 'Итого к оплате:'
  totalHelperLeft: Необходимо добрать
  totalHelperCanOrder: до минимальной суммы оформления заказа
  totalHelperFree: до бесплатной доставки
ua:
  bonusAccrual: 'Бали до зарахування:'
  bonusWithdraw: 'Бали до списання:'
  cartSum: 'Сума замовлення:'
  delivery: 'Доставка:'
  discount: 'Знижка:'
  emptyCart: Кошик пустує. Додайте до нього що-небудь.....
  notLoggedInText: 'Щоб отримати бали за це замовлення, увійдіть до '
  notLoggedInLink: Особистого кабінету.
  priorityCooking: 'Пріоритетне приготування їжі: '
  summaryOpen: Детальні розрахунки
  serviceFee: 'Збір послуг:'
  tax: 'Податок:'
  tip: 'Чайові:'
  totalPay: 'Загалом до сплати:'
  totalHelperLeft: Необхідно добрати
  totalHelperCanOrder: до мінімальної суми оформлення замовлення
  totalHelperFree: до безкоштовної доставки
us:
  bonusAccrual: 'Loyalty points to be accrued:'
  bonusWithdraw: 'Loyalty points to be withdrawn:'
  cartSum: 'Order Sum:'
  delivery: 'Delivery:'
  discount: 'Discount:'
  emptyCart: Your cart is empty. Add something to it.....
  notLoggedInText: 'To receive loyalty bonus points for this order, '
  notLoggedInLink: log in
  priorityCooking: 'Priority cooking: '
  summaryOpen: Details on purchase
  serviceFee: 'Service collection:'
  tax: 'Tax:'
  tip: 'Tip:'
  totalPay: 'Total:'
  totalHelperLeft: Your order amount need to be
  totalHelperCanOrder: more to get to minimum order amount
  totalHelperFree: more for free delivery
</i18n>

<template>
  <cart-total-summary-eryn-vorn
    v-if="appConfig.VueSettingsPreRun.TotalSummaryLayout === 'ErynVorn'"
    :cart-has-items="content"
  />
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

import { PositionType } from '~api/consts'

const accountStore = useAccountStore()
const clientStore = useClientStore()
const addressStore = useAddressStore()
const appConfig = useAppConfig()

onMounted(() => {
  clientStore.initClientState()
  accountStore.initProfile()
  addressStore.initTerminals()
  addressStore.initCities()
})

const content = computed<boolean>(() => {
  return clientStore.ClientState?.data?.Cart?.Content
    ? clientStore.ClientState?.data?.Cart?.Content.filter(
        (ci: CartItem) => ci.PositionType !== PositionType.Additional && !ci.Removed
      ).length === 0
    : false
})
</script>

<style lang="scss">
.v-summary-total {
  font-size: 1.85rem;
}
</style>
