import type { StoreState } from '~types/common'
import type {
  Action,
  Contacts,
  Feedback,
  FeedbackPayload,
  FeedbackSubjects,
  Hall,
  PageStore,
  PartnersOfferPayload,
  Proffer,
  Vacancy,
  VacancyPayload
} from '~types/pageStore'

import { type GUID, useCommon } from '@arora/common'

import { computed, ref } from 'vue'

import { defineStore } from 'pinia'
import pageAPI from '~api/page'

export const usePageStore = defineStore('pageStore', (): PageStore => {
  const Feedback = ref<Record<number, Feedback[]>>({})
  const FeedbackCount = ref<number>(0)
  const Actions = ref<StoreState<Action[]>>({
    data: null,
    error: null,
    state: null
  })
  const Contacts = ref<StoreState<Contacts>>({
    data: null,
    error: null,
    state: null
  })

  const Restaurants = ref<StoreState<Hall[]>>({
    data: null,
    error: null,
    state: null
  })
  const vacancies = ref<Vacancy[]>()
  const SelectedVacancyId = ref<GUID | undefined>()
  const SelectedPartnershipId = ref<GUID | undefined>()
  const SelectedTableReservationId = ref<GUID | undefined>()

  const ActionsOnMain = computed<Action[] | undefined>(() => {
    const { checkGuidEnable } = useCommon()

    return (Actions.value.data ?? [])
      .filter((a) => a.IsShownAtMain)
      .filter((a) => checkGuidEnable(a.Active))
      .sort((a, b) => {
        return a.Weight === undefined || a.Weight > b.Weight ? 1 : -1
      })
  })

  const ActionsFiltered = computed<Action[] | undefined>(() => {
    const { checkGuidEnable } = useCommon()

    return (Actions.value.data ?? [])
      .filter((action) => !action.EnableShowActionOnlyAsBanner && checkGuidEnable(action.Active))
      .sort((a, b) => {
        return a.Weight === undefined || a.Weight > b.Weight ? 1 : -1
      })
  })

  const ActionsAll = computed<Action[] | undefined>(() =>
    (Actions.value.data ?? []).sort((a, b) => {
      return a.Weight === undefined || a.Weight > b.Weight ? 1 : -1
    })
  )

  const StoriesSeen = ref<Map<GUID, boolean>>(new Map<GUID, boolean>())

  const Vacancies = computed<Vacancy[]>(() =>
    (vacancies.value ?? [])
      .filter((a) => a.Active)
      .sort((a, b) => {
        return a.SortWeight === undefined || a.SortWeight > b.SortWeight ? 1 : -1
      })
  )

  const RestaurantsSorted = ref<Hall[]>([])

  const RestaurantsForMap = ref<Hall[]>([])

  const RestaurantsHaveReservation = ref<boolean>(false)
  const RestaurantsShowReservationForm = ref<boolean>(false)

  function markStoryAsSeen(id: GUID): void {
    const seenStories = useCookie('seenStories', {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    })

    const parsed = seenStories.value?.split(',') ?? []

    if (!parsed.includes(id)) parsed.push(id)

    seenStories.value = parsed.join(',')

    StoriesSeen.value.set(id, true)
  }

  async function loadFeedback(payload: { page: number; count: number }): Promise<Feedback[]> {
    if (!Feedback.value[payload.page]) {
      try {
        const feedbackData = await pageAPI.loadFeedback(payload.page, payload.count)

        Feedback.value[payload.page] = feedbackData.Items
        FeedbackCount.value = feedbackData.Count
      } catch (error) {
        console.error('store request error', error)

        return []
      }
    }

    return Feedback.value[payload.page]
  }

  async function loadFeedbackSubjects(): Promise<FeedbackSubjects[]> {
    return await pageAPI.loadFeedbackSubjects()
  }

  async function loadVacancies(): Promise<void> {
    vacancies.value = await pageAPI.loadVacancies()
    if (vacancies.value.length > 0) SelectedVacancyId.value = vacancies.value[0].ID
  }

  async function loadTableReservation(): Promise<Proffer[]> {
    return await pageAPI.loadTableReservation()
  }

  async function initRestaurants(): Promise<void> {
    if (Restaurants.value.state !== 'success' && Restaurants.value.state !== 'loading') {
      await loadRestaurants()
    }
  }

  async function loadRestaurants(): Promise<void> {
    Restaurants.value.state = 'loading'

    try {
      const data = await pageAPI.loadRestaurants()

      if (data.every((a) => a.SortWeight === -1)) {
        for (const key in data) {
          data[+key].SortWeight = +key
        }
      }

      Restaurants.value.data = data
      RestaurantsHaveReservation.value = Restaurants.value.data.some((r) => r.ReservationEnabled)

      RestaurantsShowReservationForm.value = Restaurants.value.data.some(
        (r) => r.ReservationEnabled && r.ExternalReservationLink.length === 0
      )
      RestaurantsForMap.value = (Restaurants.value.data ?? []).filter(
        (a) => a.Latitude && a.Latitude !== 0 && a.Longitude && a.Longitude !== 0
      )
      RestaurantsSorted.value = (Restaurants.value.data ?? []).sort((a, b) => {
        return a.SortWeight === undefined || a.SortWeight > b.SortWeight ? 1 : -1
      })

      Restaurants.value.state = 'success'
    } catch (error) {
      Restaurants.value.error = error
      Restaurants.value.state = 'error'
    }
  }

  async function initActions(): Promise<void> {
    if (Actions.value.state !== 'success' && Actions.value.state !== 'loading') {
      await loadActions()
    }
  }

  async function loadActions(): Promise<void> {
    Actions.value.state = 'loading'

    try {
      Actions.value.data = await pageAPI.loadActions()

      if (Actions.value.data.every((a) => a.Weight === -1)) {
        for (const key in Actions.value.data) {
          Actions.value.data[+key].Weight = +key
        }
      }

      Actions.value.state = 'success'
    } catch (error) {
      Actions.value.error = error
      Actions.value.state = 'error'
    }
  }

  async function initContacts(): Promise<void> {
    if (Contacts.value.state !== 'success' && Contacts.value.state !== 'loading') {
      await loadContacts()
    }
  }

  async function loadContacts(): Promise<void> {
    Contacts.value.state = 'loading'

    try {
      Contacts.value.data = await pageAPI.loadContacts()
      Contacts.value.state = 'success'
    } catch (error) {
      Contacts.value.error = error
      Contacts.value.state = 'error'
    }
  }

  async function sendFeedback(feedbackFields: FeedbackPayload): Promise<number> {
    try {
      const { CurrentRestaurantId } = useAppConfig()
      await pageAPI.sendFeedback(CurrentRestaurantId, feedbackFields)

      return 0
    } catch (error) {
      return error ? error.code : -1
    }
  }

  async function sendVacancy(vacancyFields: VacancyPayload): Promise<number> {
    try {
      const { CurrentRestaurantId } = useAppConfig()
      await pageAPI.sendVacancy(CurrentRestaurantId, vacancyFields)

      return 0
    } catch (error) {
      return error ? error.code : -1
    }
  }

  async function sendTableReservation(tableReservationFields: VacancyPayload): Promise<number> {
    try {
      const { CurrentRestaurantId } = useAppConfig()
      await pageAPI.sendTableReservation(CurrentRestaurantId, tableReservationFields)

      return 0
    } catch (error) {
      return error ? error.code : -1
    }
  }

  async function sendPartners(partnersFields: PartnersOfferPayload): Promise<number> {
    try {
      const { CurrentRestaurantId } = useAppConfig()
      await pageAPI.sendPartners(CurrentRestaurantId, partnersFields)

      return 0
    } catch (error) {
      return error ? error.code : -1
    }
  }

  return {
    Actions,
    ActionsAll,
    ActionsFiltered,
    ActionsOnMain,
    Contacts,
    Feedback,
    FeedbackCount,
    initActions,
    initContacts,
    initRestaurants,
    loadFeedback,
    loadFeedbackSubjects,
    loadTableReservation,
    loadVacancies,
    markStoryAsSeen,
    Restaurants,
    RestaurantsForMap,
    RestaurantsHaveReservation,
    RestaurantsShowReservationForm,
    RestaurantsSorted,
    SelectedPartnershipId,
    SelectedTableReservationId,
    SelectedVacancyId,
    sendFeedback,
    sendPartners,
    sendTableReservation,
    sendVacancy,
    StoriesSeen,
    Vacancies
  }
})
