import type {
  AddToCartPayload,
  ModifierCounts,
  OfferCheckReport,
  UpdateCartItemPayload
} from '~types/clientStore'

import { type GUID, useCommon } from '@arora/common'

import { type FirstAddType, OfferCheckResultCode } from '~api/consts'

type cartFunctionsWithDialogsComposable = {
  removeCartItem: (itemId: GUID, count: number, groupID: GUID, name: string) => Promise<boolean>
  restoreCartItem: (itemId: GUID) => Promise<boolean>
  addToCartWithPayload: (actionPayload: AddToCartPayload) => Promise<boolean>
  addToCart: (
    productId: GUID,
    firstAddType: FirstAddType,
    groupID: GUID,
    name: string,
    priceModified: number,
    optionId?: GUID | null | undefined,
    modifiers?: ModifierCounts | null,
    count?: number,
    discountTimeItemId?: GUID | null,
    customName?: string | null
  ) => Promise<boolean>
  setItemCount: (itemId: GUID, count: number, groupID: GUID, name: string) => Promise<boolean>
}

export default function useCartFunctionsWithDialogs(): cartFunctionsWithDialogsComposable {
  const { objectKeys } = useCommon()
  const clientStore = useClientStore()
  const popupStore = usePopupStore()
  const { translate } = useI18nSanitized()

  async function addToCart(
    productId: GUID,
    firstAddType: FirstAddType,
    groupID: GUID,
    name: string,
    priceModified: number,
    optionId: GUID | null | undefined = null,
    modifiers: ModifierCounts | null = null,
    count = 1,
    discountTimeItemId: GUID | null = null,
    customName: string | null = null
  ): Promise<boolean> {
    let modifiersFiltered: ModifierCounts | null = null

    if (modifiers) {
      modifiersFiltered = {}

      for (const modId of objectKeys(modifiers)) {
        if (modifiers[modId] > 0) {
          modifiersFiltered[modId] = modifiers[modId]
        }
      }
    }

    const actionPayload = {
      firstAddType: firstAddType,
      product: {
        count: count,
        customName: customName,
        discountTimeItemId: discountTimeItemId,
        groupId: groupID,
        modifiers: modifiersFiltered,
        name: name,
        optionID: optionId,
        priceModified: priceModified,
        productID: productId
      }
    }

    return await addToCartWithPayload(actionPayload)
  }

  async function addToCartWithPayload(actionPayload: AddToCartPayload): Promise<boolean> {
    try {
      const updateReport = await clientStore.addProductToCart(actionPayload)

      if (updateReport.OfferCheckReport) {
        let title: string | null = null
        switch (updateReport.OfferCheckReport.Code) {
          case OfferCheckResultCode.ProductLimitedForOneCart:
            title = translate('addToCartButton.errorProductLimitedForOneCart')
            break
          case OfferCheckResultCode.OtherDiscountProdInCart:
            title = translate('addToCartButton.errorOtherDiscountProdInCart')
            break
          case OfferCheckResultCode.OtherRangedGiftProdInCart:
          case OfferCheckResultCode.OtherMultipleGiftProdInCart:
            title = translate('addToCartButton.errorOtherGiftProdInCart')
            break
          case OfferCheckResultCode.OtherBirthdayGiftInCart:
            title = translate('addToCartButton.errorOtherBirthdayGiftInCart')
            break
          case OfferCheckResultCode.NotAvailableForSelfService:
            title = translate('addToCartButton.errorNotAvailableForSelfService')
            break
          case OfferCheckResultCode.NotAvailableForDelivery:
            title = translate('addToCartButton.errorNotAvailableForDelivery')
            break
        }

        if (updateReport.OfferCheckReport.MustBeConfirmed) {
          if (popupStore.currentPopup) {
            await popupStore.closePopup()
          }

          await confirm(title, updateReport.OfferCheckReport, () => confirmFunctionAdd(actionPayload))
        } else {
          await popupStore.showWarning(updateReport.OfferCheckReport.Message, title)
        }

        return !updateReport.OfferCheckReport.MustBeConfirmed
      }

      return true
    } catch (error) {
      await popupStore.closePopup()
      await popupStore.showException(error)

      return true
    }
  }

  async function confirm(
    title: string | null,
    offerReport: OfferCheckReport,
    yesFunction: () => void
  ): Promise<void> {
    // если есть что сказать пользователю
    // и он должен принять какое-то решение
    if (offerReport.MustBeConfirmed) {
      await popupStore.showConfirm({
        message: offerReport.Message,
        title: title,
        type: 'warning',
        yesFunction: yesFunction,
        yesOrNo: true
      })
    } else if (offerReport.Code > 0) {
      // если вообще есть что сказать пользователю
      if (offerReport.Code === 7) {
        // особый случай - есть сообщение, но
        // при этом нужно чтобы оно выглядело как уведомление об
        // успешно добавленном продукте
        // нужно для акций, например для уведомления о
        // нечетном кол-ве половинок
        await popupStore.showSuccess(offerReport.Message)
      } else {
        // в остальных случаях это сообщение должно
        // выглядеть как предупреждение
        await popupStore.showWarning(offerReport.Message)
      }
    }
  }

  async function confirmFunctionAdd(actionPayload: AddToCartPayload): Promise<void> {
    await clientStore.addProductToCart({
      ...actionPayload,
      confirm: true
    })

    return
  }

  async function confirmFunctionUpdate(actionPayload: UpdateCartItemPayload): Promise<void> {
    await clientStore.updateCartItem({
      ...actionPayload,
      confirm: true
    })

    return
  }

  const removeCartItem = async (
    itemId: GUID,
    _count: number,
    groupID: GUID,
    name: string
  ): Promise<boolean> => await setItemCount(itemId, 0, groupID, name)

  async function setItemCount(
    itemId: GUID,
    count: number,
    groupID: GUID,
    name: string
  ): Promise<boolean> {
    const actionPayload = {
      count: count,
      itemId: itemId
    }

    try {
      const restaurantStore = useRestaurantStore()
      if (count === 0) {
        restaurantStore.Metrics?.sendMetricRemoveFromCart(itemId, groupID, name, count)
      }

      const setItemCountReport = await clientStore.updateCartItem(actionPayload)

      if (setItemCountReport.OfferCheckReport) {
        if (popupStore.currentPopup) {
          await popupStore.closePopup()
        }

        let title: string | null = null
        switch (setItemCountReport.OfferCheckReport.Code) {
          case OfferCheckResultCode.ProductLimitedForOneCart:
            title = translate('addToCartButton.errorProductLimitedForOneCart')
            break
          case OfferCheckResultCode.OtherDiscountProdInCart:
            title = translate('addToCartButton.errorOtherDiscountProdInCart')
            break
          case OfferCheckResultCode.OtherRangedGiftProdInCart:
          case OfferCheckResultCode.OtherMultipleGiftProdInCart:
            title = translate('addToCartButton.errorOtherGiftProdInCart')
            break
          case OfferCheckResultCode.OtherBirthdayGiftInCart:
            title = translate('addToCartButton.errorOtherBirthdayGiftInCart')
            break
          case OfferCheckResultCode.NotAvailableForSelfService:
            title = translate('addToCartButton.errorNotAvailableForSelfService')
            break
          case OfferCheckResultCode.NotAvailableForDelivery:
            title = translate('addToCartButton.errorNotAvailableForDelivery')
            break
        }

        if (setItemCountReport.OfferCheckReport.MustBeConfirmed)
          await confirm(title, setItemCountReport.OfferCheckReport, () =>
            confirmFunctionUpdate(actionPayload)
          )
        else {
          await popupStore.showWarning(setItemCountReport.OfferCheckReport.Message, title)
        }

        return !setItemCountReport.OfferCheckReport.MustBeConfirmed
        // otherwise show success message
      } else if (setItemCountReport.CartChanged) {
        // nothing to do
      }

      return true
    } catch (error) {
      await popupStore.closePopup()
      await popupStore.showException(error)

      return true
    }
  }

  async function restoreCartItem(itemId: GUID): Promise<boolean> {
    try {
      const updateReport = await clientStore.restoreCartItem(itemId)

      if (updateReport.OfferCheckReport) {
        if (popupStore.currentPopup) {
          await popupStore.closePopup()
        }

        let title: string | null = null
        switch (updateReport.OfferCheckReport.Code) {
          case OfferCheckResultCode.ProductLimitedForOneCart:
            title = translate('addToCartButton.errorProductLimitedForOneCart')
            break
          case OfferCheckResultCode.OtherDiscountProdInCart:
            title = translate('addToCartButton.errorOtherDiscountProdInCart')
            break
          case OfferCheckResultCode.OtherRangedGiftProdInCart:
          case OfferCheckResultCode.OtherMultipleGiftProdInCart:
            title = translate('addToCartButton.errorOtherGiftProdInCart')
            break
          case OfferCheckResultCode.OtherBirthdayGiftInCart:
            title = translate('addToCartButton.errorOtherBirthdayGiftInCart')
            break
          case OfferCheckResultCode.NotAvailableForSelfService:
            title = translate('addToCartButton.errorNotAvailableForSelfService')
            break
          case OfferCheckResultCode.NotAvailableForDelivery:
            title = translate('addToCartButton.errorNotAvailableForDelivery')
            break
        }

        if (updateReport.OfferCheckReport.MustBeConfirmed)
          await confirm(title, updateReport.OfferCheckReport, () =>
            clientStore.restoreCartItem(itemId, true)
          )
        else {
          await popupStore.showWarning(updateReport.OfferCheckReport.Message, title)
        }

        return !updateReport.OfferCheckReport.MustBeConfirmed
      }

      return true
    } catch (error) {
      await popupStore.closePopup()
      await popupStore.showException(error)

      return true
    }
  }

  return {
    addToCart,
    addToCartWithPayload,
    removeCartItem,
    restoreCartItem,
    setItemCount
  }
}
