import type { BreadcrumbsLinks } from '~types/props'

import type { GUID } from '@arora/common'

function buildBreadcrumbs(name: string | undefined, path: string, meta: any): void {
  const links = [] as BreadcrumbsLinks[]

  const appConfig = useAppConfig()
  const clientStore = useClientStore()
  const menuStore = useMenuStore()

  clientStore.BreadcrumbBackLink = null

  menuStore.setGroupIdManually(
    meta.GroupID ? (meta.GroupID as GUID) : null,
    meta.SubgroupID ? (meta.SubgroupID as GUID) : null
  )

  switch (name) {
    case 'about':
      links.push({
        Link: appConfig.VueSettingsPreRun.Links.AboutLink,
        Name: 'breadcrumbs.about'
      })
      break
    case 'actions-list':
      links.push({
        Link: appConfig.VueSettingsPreRun.Links.ActionsLink,
        Name: 'breadcrumbs.actionsList'
      })
      break
    case 'actions-id':
      links.push(
        {
          Link: appConfig.VueSettingsPreRun.Links.ActionsLink,
          Name: 'breadcrumbs.actionsList'
        },
        {
          Link: path,
          Name: 'breadcrumbs.actionSingle'
        }
      )
      clientStore.BreadcrumbBackLink = appConfig.VueSettingsPreRun.Links.ActionsLink
      clientStore.BreadcrumbBackText = 'breadcrumbs.actionsBack'
      break
    case 'articles-list':
      links.push({
        Link: appConfig.VueSettingsPreRun.Links.ArticlesLink,
        Name: 'breadcrumbs.articlesList'
      })
      break
    case 'restaurants-list':
      links.push({
        Link: appConfig.VueSettingsPreRun.Links.RestaurantsLink,
        Name: 'breadcrumbs.restaurantsList'
      })
      break
    case 'restaurants-id':
      links.push(
        {
          Link: appConfig.VueSettingsPreRun.Links.RestaurantsLink,
          Name: 'breadcrumbs.restaurantsList'
        },
        {
          Link: path,
          Name: 'breadcrumbs.restaurantSingle'
        }
      )
      clientStore.BreadcrumbBackLink = appConfig.VueSettingsPreRun.Links.RestaurantsLink
      clientStore.BreadcrumbBackText = 'breadcrumbs.restaurantsBack'
      break
    default:
      if (name) {
        if (name.includes('article|')) {
          links.push(
            {
              Link: appConfig.VueSettingsPreRun.Links.ArticlesLink,
              Name: 'breadcrumbs.articlesList'
            },
            {
              Link: path,
              Name: 'breadcrumbs.articleSingle'
            }
          )
          clientStore.BreadcrumbBackLink = appConfig.VueSettingsPreRun.Links.ArticlesLink
          clientStore.BreadcrumbBackText = 'breadcrumbs.articlesBack'
        }
        if (name.includes('group|') || name.includes('product|')) {
          links.push({
            Link: appConfig.VueSettingsPreRun.Links.DefaultMenuGroup,
            Name: 'breadcrumbs.menu'
          })

          if (name.includes('product|') && meta.GroupLink.length > 0) {
            links.push({
              Link: meta.GroupLink,
              MetaName: meta.GroupTitle,
              Name: ''
            })

            if (!appConfig.VueSettingsPreRun.BreadcrumbsShown) {
              clientStore.BreadcrumbBackLink = meta.GroupLink
              clientStore.BreadcrumbBackText = 'breadcrumbs.menuBack'
            }
          }

          links.push({
            Link: path,
            MetaName: meta.Title,
            Name: ''
          })
        }
      }
      break
  }

  if (links.length > 0 && appConfig.VueSettingsPreRun.BreadcrumbsShowMain) {
    links.unshift({
      Link: appConfig.VueSettingsPreRun.Links.MainLink,
      Name: 'breadcrumbs.mainPage'
    })
  }

  clientStore.Breadcrumbs = links
}

export default defineNuxtRouteMiddleware((to, from) => {
  const appConfig = useAppConfig()

  if (appConfig.SEOPreloadData.CustomRedirectMap) {
    const foundRedirect = appConfig.SEOPreloadData.CustomRedirectMap[to.path]

    if (foundRedirect && foundRedirect.length > 0) {
      return navigateTo(foundRedirect)
    }
  }

  buildBreadcrumbs(to.name?.toString(), to.path, to.meta)

  if (to.path !== from.path && import.meta.client) {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }
})
