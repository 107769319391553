<i18n>
ru:
  ariaNextSlide: Перейти к следующему слайду
  ariaPreviousSlide: Перейти к предыдущему слайду
  ariaNavigateToSlide: 'Перейти к слайду {slideNumber}'
  ariaGallery: Галерея
  itemXofY: 'Элемент {currentSlide} из {slidesCount}'
  iconArrowUp: 'Стрелка, направленная вверх'
  iconArrowDown: 'Стрелка, направленная вниз'
  iconArrowRight: 'Стрелка, направленная вправо'
  iconArrowLeft: 'Стрелка, направленная влево'
ua:
  ariaNextSlide: Перейти до наступного слайду
  ariaPreviousSlide: Перейти до попереднього слайду
  ariaNavigateToSlide: 'Перейти до слайду {slideNumber}'
  ariaGallery: Галерея
  itemXofY: 'Елемент {currentSlide} з {slidesCount}'
  iconArrowUp: 'Стрілка, направлена вгору'
  iconArrowDown: 'Стрілка, направлена вниз'
  iconArrowRight: 'Стрілка, направлена вправо'
  iconArrowLeft: 'Стрілка, направлена вліво'
us:
  ariaNextSlide: Navigate to next slide
  ariaPreviousSlide: Navigate to previous slide
  ariaNavigateToSlide: 'Navigate to slide {slideNumber}'
  ariaGallery: Gallery
  itemXofY: 'Item {currentSlide} of {slidesCount}'
  iconArrowUp: Arrow pointing upwards
  iconArrowDown: Arrow pointing downwards
  iconArrowRight: Arrow pointing to the right
  iconArrowLeft: Arrow pointing to the left
</i18n>

<template>
  <div
    v-if="title || (navigation === 'top' && (myCarousel?.data?.slidesCount ?? 0) > maxItems)"
    class="v-mb-sm v-d-flex v-flex-row v-justify-content-between"
  >
    <div
      class="v-carousel-title v-title"
      v-html="title"
    />
    <div
      v-if="navigation === 'top' && (myCarousel?.data?.slidesCount ?? 0) > maxItems"
      class="v-carousel-buttons v-d-flex"
    >
      <arora-button
        class-name="v-btn v-btn-square v-square-small v-btn-secondary v-mr-sm"
        ignore-settings
        :disabled="!loop && (myCarousel?.data?.currentSlide ?? 0) === 0"
        label="Previous"
        @click="() => myCarousel?.prev()"
      >
        <icon-general-chevron-right class="v-rotate-180" />
      </arora-button>

      <arora-button
        class-name="v-btn v-btn-square v-square-small v-btn-secondary"
        ignore-settings
        :disabled="!loop && (myCarousel?.data?.currentSlide ?? 0) >= (myCarousel?.data?.maxSlide ?? 0)"
        label="Next"
        @click="() => myCarousel?.next()"
      >
        <icon-general-chevron-right />
      </arora-button>
    </div>
  </div>
  <Carousel
    :i18n="{
      ariaNextSlide: translate('AroraCarousel.ariaNextSlide'),
      ariaPreviousSlide: translate('AroraCarousel.ariaPreviousSlide'),
      ariaNavigateToSlide: translate('AroraCarousel.ariaNavigateToSlide'),
      ariaGallery: translate('AroraCarousel.ariaGallery'),
      itemXofY: translate('AroraCarousel.itemXofY'),
      iconArrowUp: translate('AroraCarousel.iconArrowUp'),
      iconArrowDown: translate('AroraCarousel.iconArrowDown'),
      iconArrowRight: translate('AroraCarousel.iconArrowRight'),
      iconArrowLeft: translate('AroraCarousel.iconArrowLeft')
    }"
    :autoplay="autoplayDelay"
    :breakpoints="breakpoints"
    :gap="spaceBetween"
    :paginated="paginationType === 'dynamic-bullets'"
    :pause-autoplay-on-hover="autoplayDelay > 0"
    :snap-align="snapAlign"
    :wrap-around="loop"
    ref="myCarousel"
  >
    <slot />

    <template #addons>
      <Navigation
        v-if="navigation === 'on-slide'"
        class="carousel"
      />
      <template v-if="(myCarousel?.data?.slidesCount ?? 0) > maxItems">
        <Pagination v-if="paginationType === 'bullets' || paginationType === 'dynamic-bullets'" />
        <div
          v-else-if="paginationType === 'fraction'"
          class="carousel__pagination"
        >
          {{ (myCarousel?.data?.currentSlide ?? 0) + 1 }} / {{ myCarousel?.data?.slidesCount }}
        </div></template
      >
    </template>
  </Carousel>
</template>

<script setup lang="ts">
import { WindowSize } from '@arora/common'

const {
  autoplayDelay = 0,
  loop = false,
  maxItems = 4,
  minItems = 1,
  paginationType = 'bullets',
  showPartOfNextSlide = false,
  snapAlign = 'center',
  spaceBetween = 15,
  title = null
} = defineProps<{
  autoplayDelay?: number
  navigation?: 'none' | 'on-slide' | 'top'
  loop?: boolean
  spaceBetween?: number
  showPartOfNextSlide?: boolean
  maxItems?: number
  minItems?: number
  title?: string | null
  snapAlign?: 'center' | 'center-even' | 'center-odd' | 'end' | 'start'
  paginationType?: 'bullets' | 'dynamic-bullets' | 'fraction' | 'none'
}>()

type carousel = {
  next: () => void
  prev: () => void
  data: {
    currentSlide: number
    slidesCount: number
    maxSlide: number
  }
}

const { translate } = useI18nSanitized()
const myCarousel = ref<carousel | null>(null)

const appConfig = useAppConfig()

type breakpointType = Record<number, { itemsToShow: number }>

const breakpoints = computed<breakpointType | undefined>(() => {
  const addition = showPartOfNextSlide ? 0.2 : 0
  const result: breakpointType = {}

  const cardWidth = WindowSize.xl / maxItems

  for (const [, value] of Object.entries(WindowSize).reverse()) {
    const valueNumber = Number(value)
    if (!Number.isNaN(valueNumber)) {
      if (valueNumber === 0) {
        result[valueNumber] = {
          itemsToShow: minItems + addition
        }
      } else {
        const current = Math.floor(
          Math.min(valueNumber, appConfig.VueSettingsPreRun.Theme.ContainerWidth) / cardWidth
        )
        result[valueNumber] = {
          itemsToShow: (current < minItems ? minItems : current) + addition
        }
      }
    }
  }

  return result
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-carousel-title {
  font-weight: 600;
  font-size: variables.$TextSizeH2;
}
.carousel {
  text-align: start;
  --vc-pgn-border-radius: 8px;
  --vc-pgn-height: 8px;
  --vc-pgn-width: 8px;
  --vc-pgn-active-color: var(--PrimaryBackgroundColor);
  --vc-pgn-background-color: var(--BodyTextColorLight, #a3a3a3);

  &__pagination {
    bottom: -25px;

    &-button {
      &:after {
        @include mixins.trans;
      }
      &--active::after {
        width: 32px;
      }
    }
  }
}
</style>
