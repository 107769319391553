<template>
  <div
    v-if="numberOfTypes > 0 && appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader"
    class="v-d-flex"
    :class="[isDesktopVersion ? 'v-flex-row-no-wrap v-align-items-center' : 'v-flex-column']"
  >
    <div
      class="v-mr-sm"
      :style="`width: ${isDesktopVersion ? numberOfTypes * 48 : numberOfTypes * 32}px`"
    >
      <cart-order-type
        compact
        is-in-header
        order-type-show-icons
      />
    </div>
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div
        v-if="clientStore.averageTimeWithDelayMs > 0"
        class="v-average-delivery-caption--wold v-d-flex v-text-left v-align-items-center"
      >
        <span
          v-if="clientStore.ClientState.data?.OrderType === OrderType.InHall"
          class="v-mr-xs"
          v-html="translate(isSmall ? 'averageTime.captionHallMobile' : 'averageTime.captionHall')"
        />
        <span
          v-else-if="clientStore.ClientState.data?.OrderType === OrderType.NoShipment"
          class="v-mr-xs"
          v-html="translate(isSmall ? 'averageTime.captionPickupMobile' : 'averageTime.captionPickup')"
        />
        <span
          v-else
          class="v-mr-xs"
          v-html="
            translate(isSmall ? 'averageTime.captionDeliveryMobile' : 'averageTime.captionDelivery')
          "
        />
        <header-parts-average-time-base />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@arora/common'

import { OrderType } from '~api/consts'

const { translate } = useI18nSanitized()
const { isDesktopVersion, isSmall } = useWindowSize()
const addressStore = useAddressStore()
const appConfig = useAppConfig()

onMounted(async () => {
  await addressStore.initDeliveryAndPay()
})

const clientStore = useClientStore()

const numberOfTypes = computed<number>(() => {
  let result = 0
  if (addressStore.DeliveryAndPay?.data) {
    if (addressStore.DeliveryAndPay.data.EnableDeliveryCourier) result++
    if (addressStore.DeliveryAndPay.data.EnableSelfService) result++
    if (addressStore.DeliveryAndPay.data.EnableInHall) result++
  }

  return result
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-average-delivery-caption--wold {
  cursor: default;
  color: variables.$HeaderColor;
  font-size: 0.9rem;
  padding: 0 4px;
}
</style>
