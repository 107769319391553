<i18n>
ru:
  addToCart: Положить в корзину
  base: Основа
  deleteMod: Удалить модификатор
  ingredients: Ингредиенты
  renameTitle: Назовите ваше блюдо
  resetDefault: Сброс
ua:
  addToCart: Покласти в кошик
  base: Основа
  deleteMod: Видалити модифікатор
  ingredients: Інгредієнт
  renameTitle: Назвіть ваше блюдо
  resetDefault: Скидання
us:
  addToCart: Add to cart
  base: Base
  deleteMod: Remove modifier
  ingredients: Ingredients
  renameTitle: Name your meal
  resetDefault: Reset
</i18n>

<template>
  <div
    v-if="bigConstructor"
    class="v-constructor-wrapper"
    :data-test-id="`${bigConstructorDataTestID}-wrapper`"
  >
    <h1
      class="v-w-100 v-ml-sm v-font-weight-600"
      itemprop="name"
      v-html="sanitize(bigConstructor.Name)"
    />
    <menu-product-constructor-big-mordor
      v-if="appConfig.VueSettingsPreRun.BigConstructorLayout === 'Mordor'"
      :big-constructor="bigConstructor"
      :category-require-text="categoryRequireText"
      :data-test-id="bigConstructorDataTestID"
      :selected-mods-by-category="selectedModsByCategory"
      :selected-option="selectedOption"
      :set-selected-option="setSelectedOption"
    >
      <menu-product-constructor-picture-stack
        :key="selectedOption?.ID"
        :images="imagesStack"
      />
    </menu-product-constructor-big-mordor>
    <menu-product-constructor-big-durthang
      v-else-if="appConfig.VueSettingsPreRun.BigConstructorLayout === 'Durthang'"
      :big-constructor="bigConstructor"
      :category-require-text="categoryRequireText"
      :data-test-id="bigConstructorDataTestID"
      :selected-mods-by-category="selectedModsByCategory"
      :selected-option="selectedOption"
      :set-selected-option="setSelectedOption"
    >
      <menu-product-constructor-picture-stack
        :key="selectedOption?.ID"
        :images="imagesStack"
      />
    </menu-product-constructor-big-durthang>

    <div class="v-big-constructor-row">
      <div class="v-big-constructor-buttons v-text-center">
        <arora-button
          class-name="v-btn v-btn-text"
          ignore-settings
          :label="
            (bigConstructor.ConstructorResetButtonText ?? '').length > 0
              ? sanitize(bigConstructor.ConstructorResetButtonText)
              : translate('constructorBigPage.resetDefault')
          "
          @click="reset"
        />
        <div class="v-big-constructor-default-price">
          <span v-html="translate('cardPage.totalPay')" />
          <common-currency :amount="basePay + totalMods" />
        </div>
        <arora-button
          :label="translate('constructorBigPage.addToCart')"
          data-test-id="big-constructor-add-to-cart"
          @click="async () => await addToBasket()"
        />
      </div>
    </div>
  </div>
  <arora-loader v-else />
</template>

<script setup lang="ts">
import type { ModifierCounts, ModsByCategory } from '~types/clientStore'
import type { ImageInfo } from '~types/common'
import type { Category, Option, ProductConstructor } from '~types/menuStore'

import { type GUID, useCommon } from '@arora/common'

import { ConstructorRequiredSettings, FirstAddType } from '~api/consts'

const { constructorId, isRecommendation } = defineProps<{
  constructorId: GUID
  isRecommendation?: boolean
}>()

const popupStore = usePopupStore()
const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { addToCart } = useCartFunctionsWithDialogs()
const { sanitize, translate } = useI18nSanitized()
const { objectKeys, stringIsNullOrWhitespace } = useCommon()

const bigConstructor = ref<ProductConstructor | undefined>()
const selectedOption = ref<Option | undefined>(undefined)

const selectedModsByCategory = ref<ModsByCategory>({})
const imagesStack = ref<ImageInfo[]>([])

const basePay = computed<number>(() => selectedOption?.value?.Price ?? 0)

const bigConstructorDataTestID = computed(() => {
  return `big-constructor-${appConfig.VueSettingsPreRun.BigConstructorLayout}`
})

function reset(): void {
  for (const category of bigConstructor.value?.Categories ?? []) {
    for (const mod of category.ConstructorCategoryModifiers) {
      selectedModsByCategory.value[category.ID][mod.ID] = 0
    }
  }
}

function categoryRequireText(category: Category): string | null {
  let addedPerCategory = 0
  for (const mod of category.ConstructorCategoryModifiers) {
    if (
      selectedModsByCategory.value &&
      selectedModsByCategory.value[category.ID] &&
      selectedModsByCategory.value[category.ID][mod.ID] > 0
    ) {
      addedPerCategory += selectedModsByCategory.value[category.ID][mod.ID]
    }
  }

  switch (category.TypeRequire) {
    case ConstructorRequiredSettings.OneRequired:
      if (addedPerCategory !== 1) {
        return translate('cardPage.minModsRequirementsNotMetOne', {
          category: category.Name
        })
      }
      break
    case ConstructorRequiredSettings.OneAndMoreRequired:
      if (addedPerCategory < 1) {
        return translate('cardPage.minModsRequirementsNotMetOne', {
          category: category.Name
        })
      }
      break
    default:
      if (category.IngredientsMin && addedPerCategory < category.IngredientsMin) {
        return translate(
          category.IngredientsMin < 5
            ? 'cardPage.minModsRequirementsNotMetFromTwoToFour'
            : 'cardPage.minModsRequirementsNotMet',
          {
            category: category.Name,
            min: category.IngredientsMin
          }
        )
      }
      break
  }

  return null
}

const totalMods = computed<number>(() => {
  if (!selectedModsByCategory.value) return 0

  let result = 0
  for (const category of bigConstructor.value?.Categories ?? []) {
    for (const mod of category.ConstructorCategoryModifiers) {
      let count = 0
      if (selectedModsByCategory.value[category.ID] && selectedModsByCategory.value[category.ID][mod.ID])
        count = selectedModsByCategory.value[category.ID][mod.ID]

      result += count * mod.Price
    }
  }

  return result
})

async function addToBasket(): Promise<void> {
  const modifiers: ModifierCounts = {}
  let modsError: string | null = null

  for (const category of bigConstructor.value?.Categories ?? []) {
    for (const mod of category.ConstructorCategoryModifiers) {
      if (selectedModsByCategory.value[category.ID][mod.ID] > 0) {
        modifiers[mod.ID] = selectedModsByCategory.value[category.ID][mod.ID]
      }
    }

    modsError = categoryRequireText(category)
    if (modsError) break
  }
  if (modsError) {
    await popupStore.showError(modsError)
  }
  if (bigConstructor.value && selectedOption.value && !modsError) {
    if (bigConstructor.value.CanReName) {
      await popupStore.showInput({
        dataTestId: 'big-constructor-rename',
        initialText: bigConstructor.value.Name,
        onConfirm(value: string): void {
          if (bigConstructor.value && selectedOption.value)
            addToCart(
              bigConstructor.value.ID,
              isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default,
              bigConstructor.value.GroupID,
              bigConstructor.value.Name,
              bigConstructor.value.Price,
              selectedOption.value.ID,
              modifiers,
              1,
              null,
              stringIsNullOrWhitespace(value) ? bigConstructor.value.Name : value
            )
        },
        title: translate('constructorBigPage.renameTitle')
      })
    } else {
      await addToCart(
        bigConstructor.value.ID,
        isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default,
        bigConstructor.value.GroupID,
        bigConstructor.value.Name,
        bigConstructor.value.Price,
        selectedOption.value.ID,
        modifiers,
        1
      )
    }
  }
}

onMounted(async () => {
  bigConstructor.value = appConfig.Constructors.find((constructor) => constructor.ID === constructorId)

  if (bigConstructor.value) {
    const optionsKeys = bigConstructor.value.Options ? objectKeys(bigConstructor.value.Options) : []

    if (optionsKeys.length > 0) {
      for (const key of optionsKeys)
        for (const option of Object.values(bigConstructor.value?.Options[key] as Option[])) {
          if (option.IsDefault && !menuStore.StopListMap.OptionIds.includes(option.ID)) {
            selectedOption.value = option
          }
        }

      if (!selectedOption.value) {
        for (const key of optionsKeys)
          for (const option of Object.values(bigConstructor.value?.Options[key] as Option[])) {
            if (!menuStore.StopListMap.OptionIds.includes(option.ID)) {
              selectedOption.value = option
              break
            }
          }
      }
      if (!selectedOption.value) return

      menuStore.SelectedOptionsPerProduct.set(constructorId, selectedOption.value)

      if (selectedOption.value.BigImage) imagesStack.value[0] = selectedOption.value.BigImage
    }

    selectedModsByCategory.value = {}
    for (const category of bigConstructor.value?.Categories ?? []) {
      selectedModsByCategory.value[category.ID] = {}
      for (const mod of category.ConstructorCategoryModifiers) {
        selectedModsByCategory.value[category.ID][mod.ID] = 0
      }
    }
  }
})

function setSelectedOption(firstLevel: GUID, optionId: GUID): void {
  selectedOption.value = bigConstructor.value?.Options[firstLevel]?.find((option: Option) => {
    return option.ID === optionId
  })

  selectedModsByCategory.value = {}
  for (const category of bigConstructor.value?.Categories ?? []) {
    selectedModsByCategory.value[category.ID] = {}
    for (const mod of category.ConstructorCategoryModifiers) {
      selectedModsByCategory.value[category.ID][mod.ID] = 0
    }
  }
}

function triggerImage(newState: ModsByCategory): void {
  imagesStack.value = []

  const option = Object.values(bigConstructor.value?.Options ?? {})
    .flat(1)
    .find((option: Option) => option.ID === selectedOption.value?.ID)

  if (option?.BigImage) imagesStack.value.push(option.BigImage)

  for (const cat of bigConstructor.value?.Categories ?? []) {
    if (newState[cat.ID]) {
      for (const mod of cat.ConstructorCategoryModifiers) {
        if (newState[cat.ID][mod.ID] && newState[cat.ID][mod.ID] > 0 && mod.AppliedImageBig) {
          imagesStack.value.push(mod.AppliedImageBig)
        }
      }
    }
  }
}

watch(
  () => selectedModsByCategory.value,
  async (newState) => triggerImage(newState),
  { deep: true }
)

watch(
  () => selectedOption.value,
  async () => triggerImage(selectedModsByCategory.value)
)

watch(
  () => menuStore.SelectedOptionsPerProduct,
  () => {
    selectedOption.value = menuStore.SelectedOptionsPerProduct.get(constructorId)
  },
  { deep: true }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-constructor-wrapper {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;

  @include mixins.md {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.v-big-constructor-row {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 15px;

  @include mixins.md {
    padding: 25px 5px;
  }
}

.v-big-constructor-buttons {
  flex: 0 0 100%;
  max-width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 22px;

  @include mixins.md {
    flex-direction: column;
    padding: 0 35px;

    .v-big-constructor-default-price {
      margin-bottom: 1.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .v-btn {
      margin-bottom: 1.1rem;
    }
  }

  .v-btn {
    font-size: 16px;
  }
}
</style>
