<template>
  <div class="v-container v-p-0">
    <div class="v-row v-mb-md">
      <div
        class="v-col-12 v-mb-sm v-account-management--title v-title"
        v-html="translate(onlyBySms ? 'accountManagement.titleLogin' : 'accountManagement.register')"
      />
      <div class="v-col-12 v-mb-xxs v-account-management--subtitle v-d-flex v-flex-row">
        <span
          v-if="authVariant === AuthVariant.Telegram"
          class="v-text"
          v-html="translate('accountManagement.telegramHelpSecond')"
        />
        <span
          v-else-if="authVariant === AuthVariant.Flashcall"
          class="v-text"
          v-html="translate('accountManagement.flashcallHelpSecond')"
        />
        <span
          v-else
          class="v-text"
          v-html="translate('accountManagement.smsHelpSecond')"
        />
        <span
          class="v-phone-user"
          v-html="phoneBeautified(phone)"
        />
      </div>
      <div class="v-col-12 v-account-management--subtitle">
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link v-btn-link-secondary v-no-underline v-d-flex"
          ignore-settings
          :label="translate('accountManagement.changePhone')"
          @click="setLKModeBack"
        />
      </div>
    </div>
    <div class="v-row v-mb-md">
      <div class="v-col-12">
        <v-input
          class-name="v-arora-input"
          focus-on-mount
          required
          v-form-validator="{
            Form: validatorForm,
            Value: registerFields.code,
            Required: true,
            Validations: ['noSpace', 'length'],
            MaxLength: registerFields.codeMaxLength
          }"
          :label="authVariantCode(authVariant!)"
          v-model:text="registerFields.code"
        />
      </div>
      <div class="v-auth-type--help__light v-mb-sm">
        <div class="v-icon">
          <icon-general-information-circle class="v-img-fluid v-w-100" />
        </div>
        <div
          v-if="authVariant === AuthVariant.Telegram"
          class="v-text"
        >
          <span
            class="v-mr-xxs"
            v-html="translate('accountManagement.telegramHelpForInputSecondLeft')"
          />
          <arora-button
            v-if="registerFields.externalAuthLink"
            id="auth-variant-telegram-link"
            class-name="v-btn v-btn-link v-link v-btn-link-secondary v-mr-xxs"
            ignore-settings
            :label="translate('accountManagement.telegramHelpForInputSecondLink')"
            @click="linkClick"
          />
          <span
            v-else
            v-html="translate('accountManagement.telegramHelpForInputSecondLink')"
          />
          <span v-html="translate('accountManagement.telegramHelpForInputSecondRight')" />
        </div>
        <div
          v-else-if="authVariant === AuthVariant.Flashcall"
          class="v-text"
          v-html="translate('accountManagement.flashcallHelpForInputSecond')"
        />
        <div
          v-else
          class="v-text v-d-flex v-align-items-center"
          v-html="translate('accountManagement.smsHelpForInputSecond')"
        />
      </div>

      <template v-if="!onlyBySms">
        <div
          v-if="appConfig.VueSettingsPreRun.RegisterShowName"
          class="v-col-12"
        >
          <v-input
            class-name="v-arora-input--border__secondary v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: name,
              Required: appConfig.VueSettingsPreRun.RegisterNameRequired,
              Validations: ['text', 'length'],
              MaxLength: 60
            }"
            :label="translate('accountManagement.name')"
            :required="appConfig.VueSettingsPreRun.RegisterNameRequired"
            v-model:text="name"
          />
        </div>
        <div
          v-if="appConfig.VueSettingsPreRun.RegisterShowSurname"
          class="v-col-12"
        >
          <v-input
            class-name="v-arora-input--border__secondary v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: surname,
              Required: appConfig.VueSettingsPreRun.RegisterSurnameRequired,
              Validations: ['text', 'length'],
              MaxLength: 60
            }"
            :label="translate('accountManagement.surname')"
            :required="appConfig.VueSettingsPreRun.RegisterSurnameRequired"
            v-model:text="surname"
          />
        </div>
        <div
          v-if="appConfig.VueSettingsPreRun.RegisterShowEmail"
          class="v-col-6"
        >
          <v-email-input
            class-name="v-arora-input--border__secondary v-arora-input"
            :required="
              appConfig.VueSettingsPreRun.RegisterEmailRequired ||
              (appConfig.VueSettingsPreRun.RegisterShowNotifyEmail &&
                (appConfig.VueSettingsPreRun.RegisterNotifyEmailRequired || registerFields.receiveEmail))
            "
            v-form-validator="{
              Form: validatorForm,
              Value: email,
              Required:
                appConfig.VueSettingsPreRun.RegisterEmailRequired ||
                (appConfig.VueSettingsPreRun.RegisterShowNotifyEmail &&
                  (appConfig.VueSettingsPreRun.RegisterNotifyEmailRequired ||
                    registerFields.receiveEmail)),
              RequiredText: registerFields.receiveEmail
                ? translate('accountManagement.emailNotifyRequired')
                : undefined,
              Validations: ['email', 'length'],
              MaxLength: 60
            }"
            :label="translate('accountManagement.email')"
            v-model:text="email"
          />
        </div>
        <div
          v-if="appConfig.VueSettingsPreRun.RegisterShowBDay"
          class="v-col-6"
        >
          <arora-date-input
            v-if="appConfig.VueSettingsPreRun.RegisterShowBDay"
            class="v-arora-input--border__secondary"
            v-form-validator="{
              Form: validatorForm,
              Value: registerFields.bday,
              Required: appConfig.VueSettingsPreRun.RegisterBDayRequired,
              Validations: ['birthday']
            }"
            :label="translate('accountManagement.bday')"
            :required="appConfig.VueSettingsPreRun.RegisterBDayRequired"
            v-model:text="registerFields.bday"
          />
        </div>
        <div
          v-if="appConfig.VueSettingsPreRun.RegisterShowGender"
          class="v-col-12"
          v-form-validator="{
            Form: validatorForm,
            Value: gender !== null ? '#' : null,
            Required: appConfig.VueSettingsPreRun.RegisterGenderRequired,
            RequiredText: translate('accountManagement.genderRequired'),
            Validations: []
          }"
        >
          <arora-radio-input
            as-column
            :label="translate('accountManagement.gender')"
            :options="[true, false]"
            :required="appConfig.VueSettingsPreRun.RegisterGenderRequired"
            v-model:selected="gender"
          >
            <template #option="option">
              <span
                v-if="option.value === null"
                v-html="translate('accountManagement.genderNotSelected')"
              />
              <span
                v-else
                v-html="
                  translate(
                    option.value ? 'accountManagement.genderMale' : 'accountManagement.genderFemale'
                  )
                "
              />
            </template>
          </arora-radio-input>
          <hr />
        </div>
        <div
          v-if="
            appConfig.VueSettingsPreRun.RegisterShowNotifyEmail ||
            appConfig.VueSettingsPreRun.RegisterShowNotifySms
          "
          class="v-col-12"
        >
          <div class="v-account-management-line-input">
            <arora-switch
              v-if="appConfig.VueSettingsPreRun.RegisterShowNotifyEmail"
              v-form-validator="{
                Form: validatorForm,
                Value: registerFields.receiveEmail ? '#' : '',
                Required: appConfig.VueSettingsPreRun.RegisterNotifyEmailRequired,
                RequiredText: translate('accountManagement.notifyRequired'),
                Validations: []
              }"
              :label="translate('accountManagement.receiveEmail')"
              v-model:checked="registerFields.receiveEmail"
            />
            <arora-switch
              v-if="appConfig.VueSettingsPreRun.RegisterShowNotifySms"
              v-form-validator="{
                Form: validatorForm,
                Value: registerFields.receiveSms ? '#' : '',
                Required: appConfig.VueSettingsPreRun.RegisterNotifySmsRequired,
                RequiredText: translate('accountManagement.notifyRequired'),
                Validations: []
              }"
              :label="translate('accountManagement.receiveSms')"
              v-model:checked="registerFields.receiveSms"
            />
          </div>
          <hr />
        </div>
      </template>
    </div>
    <div class="v-row v-mb-md">
      <div
        v-if="appConfig.VueSettingsPreRun.EnablePersonalDataWarning"
        class="v-col-12"
      >
        <span
          class="v-mr-xxs"
          v-html="translate('accountManagement.personalDataMessage')"
        />
        <arora-nuxt-link
          class-name="v-link"
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.PersonalDataLink"
          :label="translate('accountManagement.personalDataMessageLink')"
        />
      </div>

      <div
        v-else
        class="v-col-12"
      >
        <span v-html="translate('accountManagement.agreementText')" />
        <arora-nuxt-link
          class-name="v-link v-ml-xxs"
          open-in-new-tab
          :href="appConfig.VueSettingsPreRun.Links.AgreementLink"
          :label="translate('accountManagement.agreementLink')"
        />
      </div>
    </div>
    <div class="v-row">
      <div
        v-if="!onlyBySms"
        class="v-col-12 v-col-md-6 v-mb-sm"
      >
        <arora-button
          class-name="v-btn-login-link v-btn v-btn-link"
          ignore-settings
          :label="translate('accountManagement.alreadyRegistered')"
          @click="setLKModeLogin"
        />
      </div>
      <div
        class="v-col-12 v-col-md-6 v-mb-sm"
        :class="{ 'v-offset-md-6': onlyBySms }"
      >
        <arora-button
          class-name="v-w-100"
          :disabled="registerDisabled || clicked"
          :label="translate(onlyBySms ? 'accountManagement.login' : 'accountManagement.register')"
          @click="async () => await register()"
        />
      </div>
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="loginError"
          class="v-col-12 v-text-center v-error-color"
          v-html="loginError"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RegisterPayload } from '~types/accountStore'
import type { AccountManagementCommon } from '~types/props'

import {
  type ServerError,
  useCommon,
  useValidationStore,
  VEmailInput,
  vFormValidator,
  VInput
} from '@arora/common'

import { getActivePinia } from 'pinia'
import { AuthVariant, LKMode } from '~api/consts'

const { authVariant, fields, onlyBySms, phone } = defineProps<
  AccountManagementCommon & {
    fields: RegisterPayload
  }
>()

const emit = defineEmits(['update:mode', 'update:phone', 'update:fields'])

const registerFields = computed<RegisterPayload>({
  get() {
    return fields
  },
  set(value: RegisterPayload) {
    emit('update:fields', value)
  }
})
const accountStore = useAccountStore()
const validationStore = useValidationStore(getActivePinia())

const { capitalLetter, stringIsNullOrWhitespace } = useCommon()
const { handleAuthError } = useAccount()
const { clean, translate } = useI18nSanitized()
const { phoneBeautified } = usePhoneNumber()

const { eventEmit } = useEmitter()

const validatorForm = 'registerSecondStage'

const appConfig = useAppConfig()
function setLKModeBack(): void {
  emit('update:mode', onlyBySms ? LKMode.LoginBySms : LKMode.RegisterFirstStage)
}

const loginError = ref<string | null>(null)
const registerDisabled = computed<boolean>(() => {
  if (
    !registerFields.value.code ||
    registerFields.value.code.length < registerFields.value.codeMaxLength
  )
    return true

  if (onlyBySms) return false

  if (
    appConfig.VueSettingsPreRun.RegisterNameRequired &&
    stringIsNullOrWhitespace(registerFields.value.name)
  )
    return true
  if (
    appConfig.VueSettingsPreRun.RegisterSurnameRequired &&
    stringIsNullOrWhitespace(registerFields.value.surname)
  )
    return true
  if (appConfig.VueSettingsPreRun.RegisterGenderRequired && registerFields.value.male === null)
    return true
  if (
    appConfig.VueSettingsPreRun.RegisterBDayRequired &&
    stringIsNullOrWhitespace(registerFields.value.bday)
  )
    return true

  //by default
  return false
})

async function handleErrorMessage(error: ServerError): Promise<void> {
  loginError.value = await handleAuthError(error)
}

function setLKModeLogin(): void {
  emit('update:mode', LKMode.Login)
}

const clicked = ref<boolean>(false)

const name = computed<string | null>({
  get() {
    return registerFields.value.name
  },
  set(value: string | null) {
    const valueCapital = capitalLetter(clean(value ?? ''))

    if (stringIsNullOrWhitespace(valueCapital)) {
      registerFields.value.name = null
    } else {
      registerFields.value.name = valueCapital
    }
  }
})

const gender = computed<boolean | null>({
  get() {
    if (!registerFields.value) return null

    return registerFields.value.maleIsNull ? null : registerFields.value.male
  },
  set(value: boolean | null) {
    if (registerFields.value) {
      registerFields.value.male = value ?? false
      registerFields.value.maleIsNull = value === null
    }
  }
})

const surname = computed<string | null>({
  get() {
    return registerFields.value.surname
  },
  set(value: string | null) {
    const valueCapital = capitalLetter(clean(value ?? ''))

    if (stringIsNullOrWhitespace(valueCapital)) {
      registerFields.value.surname = null
    } else {
      registerFields.value.surname = valueCapital
    }
  }
})

const email = computed<string | null>({
  get() {
    return registerFields.value.email
  },
  set(value: string | null) {
    const valueFixed = clean(value ?? '')

    if (stringIsNullOrWhitespace(valueFixed)) {
      registerFields.value.email = null
    } else {
      registerFields.value.email = valueFixed
    }
  }
})

async function register(): Promise<void> {
  clicked.value = true
  registerFields.value.country = appConfig.VueSettingsPreRun.SelectedLanguage

  if (!validationStore.formPassedCheck(validatorForm)) {
    clicked.value = false

    return
  }

  const error = await accountStore.register(registerFields.value)
  await handleErrorMessage(error)
  if (error.code === 0) reloadNuxtApp()
  else eventEmit('v-reset-captcha')

  clicked.value = false
}

function linkClick(): void {
  setTimeout(() => window.open(registerFields.value.externalAuthLink ?? '/', '_blank'))
}

onMounted(() => {
  if (registerFields.value.receiveEmail) {
    validationStore.formPassedCheck(validatorForm)
  }

  if (authVariant === AuthVariant.Telegram) linkClick()
})
</script>

<style lang="scss">
.v-account-management-line-input {
  .v-subscription-message {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .v-switch {
    margin-bottom: 10px;
  }
}
</style>
