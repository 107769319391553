@@ -1,127 +0,0 @@
<i18n>
ru:
  login: Войти
  logout: Выйти
  points: Баллы
  profile: Профиль
ua:
  login: Увійти
  logout: Вийти
  points: Бал
  profile: Профіль
us:
  login: Log in
  logout: Log out
  points: Points
  profile: Profile
</i18n>

<template>
  <account-login-button-umbar
    v-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar'"
    :show-popup="showPopup"
  />
  <account-login-button-wold
    v-else-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Wold'"
    :show-popup="showPopup"
  />
</template>

<script setup lang="ts">
const { hideCloseButton } = defineProps<{
  hideCloseButton?: boolean //hide close button in popup
}>()

const popupStore = usePopupStore()

const { fromPopup } = useInstance()
const { eventEmit } = useEmitter()
const appConfig = useAppConfig()

async function showPopup(): Promise<void> {
  if (fromPopup.value) {
    //close previous popup
    await popupStore.closePopup()
  }
  await popupStore.openPopup({
    onClosePopup: () => {
      eventEmit('v-reset-captcha')
    },
    popupClosable: hideCloseButtonTotal.value,
    popupName: 'accountManagementPopup'
  })
}

const hideCloseButtonTotal = computed<boolean>(() => {
  return appConfig.VueSettingsPreRun.LoginShowCloseButton || (hideCloseButton ?? false)
})

onMounted(async () => {
  if (appConfig.VueSettingsPreRun.LoginShowOnEntrance) {
    //in case there will be client who wants to show login popup on enter
    await showPopup()
  }
})
</script>
